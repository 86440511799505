// if ($(window).width() < 1024) {
// 	$("body").addClass("hideMobile");
// }
function breakTitleLetters($title) {
	if($title.length <= 0) return;

	var lines = $title.text().split("&nbsp;"),
			$newTitle = $title;

	$title.html("");

	for(var i=0; i<lines.length; i++) {
		var title_string = lines[i];

		for (var j = 0; j < title_string.length; j++) {
			if(title_string[j] == " "){
				title_string[j] = '&nbsp;';
			}
			var letter = "<span class='char'>" + title_string[j] + "</span>";
			$title.append(letter);
		}
	}
}

function breakIntroLines($title) {

  if($title.length <= 0) return;

  var lines = $title.html().split("<br>"),
      $newTitle = $title;

  $newTitle.empty();
  for(var i=0; i<lines.length; i++) {
    var $newLine = $("<span class='line'>"+lines[i]+"</span><br>");
    $newTitle.append($newLine);
  }

  var $offsetText = $newTitle.find("span:not(.line)").detach();
  $newTitle.append($offsetText);

  $title.html($newTitle.html());

}

function breakTitleLines($title){
	if($title.length <= 0) return;

	var lines = $title.html().split("<br>"),
			$newTitle = $title;

	$newTitle.empty();
	for(var i=0; i<lines.length; i++) {
		var $newLine = $("<span class='line'>"+lines[i]+"</span>");
		$newTitle.append($newLine);
	}
	$(".frame-title .line").each(function(){
		var words = $(this).text().split(" "),
				$this = $(this);
		$this.text("");
		for(var i=0; i<words.length; i++) {
			var title_string = words[i],
					$word = $("<span class='word'>" + title_string + "&nbsp;</span>");
			$this.append($word);
		};
		$this.eq(0).append("<br>");
	});

	var $word = $(".word"),
			$line = $(".page-current .line");

	$word.each(function(){
		var $this = $(this);
		$this.html($(this).text().replace(/([^\x64-\x80]|\w)/g, "<span class='letter'>$&</span>"));
	});
	$line.each(function(){
		var $this = $(this);
		$this.find($word).last().find(".letter").last().remove();
	});
}

/*******************************************************************************************
 **                                                                                       **
	MENU STUFF
 **                                                                                       **
*********************************************************************************************/
function menuAnimations(){
	var open = function(){
		TweenMax.set("#menu-container", {autoAlpha: 1});
		TweenMax.set("#menu-container .carousel-wrapper li.active .frame", { opacity: 0 });
		TweenMax.set("#page-indicator-container", {opacity: 1 });

		if (!$_body.hasClass("home")) {
			TweenMax.to(".header-links",1, {color: "#ffffff", ease: Quad.easeOut});
			TweenMax.to("#hamburguer-container #lines span",1, {backgroundColor: "#ffffff", ease: Quad.easeOut});
			TweenMax.to("#hamburguer-container .arrow-menu path",1, {css:{stroke: "#ffffff"}, ease: Quad.easeOut});
			TweenMax.to("#cipro-logo svg .logo-color",1, {css:{fill: "#ffffff"}, ease: Quad.easeOut});
			TweenMax.to("#hamburguer-container .frame-menu polyline",1, {css:{stroke: "rgba(255, 255, 255, 0.3)"}, ease: Quad.easeOut});
		}

		$_headerMain.addClass("white-background");
		TweenMax.set("#menu-container .carousel-wrapper li.active .frame", { opacity: .3, delay: 2 });
		
		if ($_body.hasClass("home")) {
			anime.timeline()
				.add({
					targets: '.type-menu .char',
					translateY: [0, -100],
					easing: "easeInQuad",
					duration: 1400,
					offset: "-=500",
					delay: function(el, i) {
						return 500 + 50 * i;
					}
				}).add({
					targets: '.type-close .char',
					translateY: [100,0],
					easing: "easeOutQuad",
					duration: 1100,
					offset: "-=2500",
					delay: function(el, i) {
						return 500 + 50 * i;
					}
				});
		} else {
			anime.timeline()
				.add({
					targets: '.type-page-name .char',
					translateY: [0, -100],
					easing: "easeInQuad",
					duration: 1400,
					offset: "-=500",
					delay: function(el, i) {
						return 500 + 50 * i;
					}
				}).add({
					targets: '.type-close .char',
					translateY: [100,0],
					easing: "easeOutQuad",
					duration: 1100,
					offset: "-=2500",
					delay: function(el, i) {
						return 500 + 50 * i;
					}
				});
		}
		if (!$_html.hasClass("ie") && !$_html.hasClass("edge") && !$_body.hasClass("mobile")) {
			_customScroll.unregisterEvents(/scroll/, /wheel/, /touchstart/);
		}
	}

	var close = function(){
		TweenMax.set("#menu-container", {autoAlpha: 0});

		var $pageName = $(".type-page-name ").html($(".page-main.page-next .page-toload").attr("data-titlePage"));
    breakTitleLetters($pageName);

		if (!$_body.hasClass("home")) {
			TweenMax.to(".header-links", 0.6, {color: "#a48254", ease: Quad.easeOut});
			TweenMax.to("#hamburguer-container #lines span", 0.6, {backgroundColor: "#a48254", ease: Quad.easeOut});
			TweenMax.to("#hamburguer-container .arrow-menu path", 0.6, {css:{stroke: "#a48254"}, ease: Quad.easeOut});
			TweenMax.to("#cipro-logo svg .logo-color", 0.6, {css:{fill: "#a48254"}, ease: Quad.easeOut});
			TweenMax.to("#hamburguer-container .frame-menu polyline", 0.6, {css:{stroke: "rgba(164, 130, 84, 0.3)"}, ease: Quad.easeOut});
		}
		if ($_html.hasClass("firefox")) {
			TweenMax.to(".page-current .page-header, .page-current .page-content", 0.6, {opacity: 1, ease: Quad.easeOut});
		} else {
			TweenMax.to(".page-current .page-header, .page-current .page-content", 0.6, {filter: "blur(0px)", webkitFilter: "blur(0px)", ease: Expo.easeOut});
		}
		$_headerMain.removeClass("white-background");
		if ($_body.hasClass("home")) {
			anime.timeline()
				.add({
					targets: '.type-close .char',
					translateY: [0, 100],
					easing: "easeInQuad",
					duration: 1400,
					offset: "-=500",
					delay: function(el, i) {
						return 500 + 50 * i;
					}
				}).add({
					targets: '.type-menu .char',
					translateY: [-100,0],
					easing: "easeOutQuad",
					duration: 1100,
					offset: "-=2500",
					delay: function(el, i) {
						return 500 + 50 * i;
					}
				});
		} else { 
			anime.timeline()
				.add({
					targets: '.type-close .char',
					translateY: [0, 100],
					easing: "easeInQuad",
					duration: 1400,
					offset: "-=500",
					delay: function(el, i) {
						return 500 + 50 * i;
					}
				}).add({
					targets: '.type-page-name .char',
					translateY: [-100,0],
					easing: "easeOutQuad",
					duration: 1100,
					offset: "-=2500",
					delay: function(el, i) {
						return 500 + 50 * i;
					}
				});
		}
		if (!$_html.hasClass("ie") && !$_html.hasClass("edge") && !$_body.hasClass("mobile")) {
			_customScroll.registerEvents(/scroll/, /wheel/, /touchstart/);
		}
	}

	return{
		open: open,
		close: close
	}
}

$(document).on('toggleMenu', function(){
	if (!$_body.hasClass("mobile")) {
		if (!_canOpenMenu) {
			_canvasVideoObj.zoomOut();
			_audioObj.fadeTo(.1);

			if(!$_body.hasClass("mobile"))
				$("#mouse-drag").css("display", "block");
			$(".audio-helper-container").addClass("whiteColor");
			$("#hamburguer-container").addClass("active");
			TweenMax.set($("#mouse-drag"), {
				x: _mouseX,
				y: _mouseY + _scrollY
			});
			$_window.on('mousemove.menuDrag', menuDragFollow);
			$_window.on('mousedown.menuDrag', menuDragClick);
			$_window.on('mouseup.menuDrag', menuDragUnclick);
			TweenMax.set(".pseudo-poster", {autoAlpha: 0});
		} else {
			_canvasVideoObj.zoomIn("close");
			_audioObj.fadeTo(.5);

			$_window.off('mousemove.menuDrag');
			$_window.on('mousedown.menuDrag');
			$_window.on('mouseup.menuDrag');
			$("#mouse-drag").css("display", "none");
			TweenMax.to($("#mouse-drag"), 0.6, {scale: 0, ease: Quad.easeOut});
			$(".audio-helper-container").removeClass("whiteColor");
			$("#hamburguer-container").removeClass("active");
		}
	} else {
		if (!_canOpenMenu) {
			TweenMax.set($("#lines > span"), {backgroundColor: "#fff" });
			// $("#page-indicator-container").css("top", (_scrollY + _globalViewportH / 2 - $("#page-indicator-container").height() / 2)+"px");
			$("#page-indicator-container").css({"top": 0, "height":_globalViewportH});
			$_headerMain.removeClass("scroll-up");
			_canOpenMenu = true;

			if (!$_html.hasClass("ie") && !$_html.hasClass("edge") && !$_body.hasClass("mobile")) {
				_customScroll.unregisterEvents(/scroll/, /wheel/, /touchstart/);
			}

			TweenMax.set("#menu-container", {height: _globalViewportH});
			$("#menu-container").addClass("opened");
			$("#hamburguer-container").addClass("active");

			TweenMax.to(".logo-color", .5, { fill: "rgb(255,255,255)" });
			TweenMax.set($(".frame-menu > polyline"), {stroke: "rgba(255,255,255,1)" });

			TweenMax.set($("#menu-container"), {x: -_globalViewportW});
			TweenMax.set($("#menu-container li"), {"opacity": 0, x: "-50px"});
			var delayTime = 0.1;
			$("#menu-container li").each(function() {
				TweenMax.to($(this), 0.1, {"opacity": 1, x: 0, force3D: true, ease: Power2.easeInOut, delay: delayTime });
				delayTime += .1;
			});
			TweenMax.to($("#menu-container"), 0.6, {x: 0, force3D: true, ease: Power2.easeInOut});
			
		} else {
			_canOpenMenu = false;
			if (!$_body.hasClass("mobile")) {
				$_headerMain.addClass("scroll-up");
			} else {
				if (!$_body.hasClass("home")) {
					if (_scrollY > 0) {
						TweenMax.to(".logo-color", .5, { fill: "rgb(164, 130, 84)" });
						TweenMax.set($("#lines > span"), {backgroundColor: "#a48254" });
						TweenMax.set($(".frame-menu > polyline"), {stroke: "rgba(164, 130, 84,0.3)" });
						$("#menu-container").removeClass("opened");
						$("#hamburguer-container").removeClass("active");
						$_headerMain.addClass("scroll-up");
					}
				}
			}

			if (!$_html.hasClass("ie") && !$_html.hasClass("edge") && !$_body.hasClass("mobile")) {
				_customScroll.registerEvents(/scroll/, /wheel/, /touchstart/);
			}

			// $("#menu-container").removeClass("opened");
			$("#hamburguer-container").removeClass("active");
			// $_headerMain.addClass("scroll-up");


			TweenMax.set($("#menu-container"), {x: -_globalViewportW});
			TweenMax.set($("#menu-container li"), {"opacity": 0, x: "-50px"}); 
		}
	}
});

$("#hamburguer-container").on("click", function(){
  var $this = $(this);
  if (_canClick){
  	$(document).trigger('toggleMenu');
  }
});


/*******************************************************************************************
 **                                                                                       **
	MENU DRAG
 **                                                                                       **
*********************************************************************************************/
function menuDrag(){
	var $menuCarousel = $("#menu-carousel")
		$container = $("#menu-container"),
		$mouseDrag = $("#mouse-drag"),
		$dragTitle = $(".drag"),
		$dragArrowContainer = $(".drag-arrow-container"),
		slides = $("#menu-container #menu-carousel ul li"),
		$sliderList = $("#menu-carousel .carousel-wrapper"),
		$pageIndicatorContainer = $("#page-indicator-container ul li"),
		$scrollIndicatorMenu = $("#page-indicator-container"),
		snapPoints = [],
		left_array = [],
		currentSlide = 0,
		slideWidth = slides.outerWidth(true),
		lowerLimit = -(slides.length-1)*slideWidth,
		upperLimit = 0,
		mouse_down = false,
		initial_value = 0,
		current_offset= 0,
		drag_val = 0,
		carousel_width = _globalViewportW,
		teste = 0;

	$_window.on('resize.menuDrag', $.debounce(1000, resizeMenu));
	$container.find(".carousel-wrapper").css('width', slideWidth*slides.length + _globalViewportW/2);
	$("#menu-container #menu-carousel ul").css({
		"padding-left": (_globalViewportW - slides.outerWidth())/2,
		"padding-right": (_globalViewportW - slides.outerWidth())/2
	});

	for (var i = 0; i < slides.length; i++) {
	  snapPoints.push(-slideWidth * i);
	}

	var bounds = {minX:-slideWidth*4, maxX:0};


	var draggable = Draggable.create("#menu-carousel .carousel-wrapper", {
	  	type : "x",
	  	throwProps : true,
	  	dragResistance:0, 
	  	throwResistance:0, 
	  	bounds: bounds,
	  	force3D: true,
	  	onThrowComplete : function() {
	    	currentSlide = Math.round(Math.abs(this.endX/slideWidth));
	    	this.applyBounds({minX:Math.max((-slideWidth*(currentSlide+1)),lowerLimit), maxX:Math.min((-slideWidth*(currentSlide-1)),upperLimit)});
	  	},
	  	onPress:function() {
	    	$("#menu-carousel").addClass("holding");
			},
			onRelease:function() {
				var index = giveClassActive(this);

				_canvasVideoObj.playOnMenu(index);
	  	},
	  	onDragStart:function() {
	    	_canvasVideoObj.pauseOnMenu();
	  	},
	  	snap:snapPoints
	});

	function giveClassActive(elem){ 
		for (var i = 0; i <= $("#menu-container #menu-carousel ul li").length-1; i++) {
			if (Math.round(elem.endX) == Math.round(-slideWidth*i)) { 
				$("#menu-carousel ul li").removeClass("active");
				$("#menu-carousel ul li").eq(i).addClass("active");
				$("#page-indicator-container ul li").removeClass("active");
				$("#page-indicator-container ul li").eq(i).addClass("active");
				TweenMax.to("#line", 1, {x: $("#line").width()*i, ease: Quad.easeOut});
				return i;
				
			}
		}
	}

	function resizeMenu(){
		$("#page-indicator-container").css("height", $("#page-indicator-container ul").find("li").height()*7 + "px");
		$("#page-indicator-container ul").css("width", $("#menu-container #menu-carousel ul").find("li").eq(0).width() + "px");
		$("#full-line-container #line").css("width", $("#page-indicator-container ul li").width() + "px");
		$scrollIndicatorMenu.css("left", (_globalViewportW-$scrollIndicatorMenu.width()) / 2 + "px");
		$scrollIndicatorMenu.css("top",_globalViewportH - $scrollIndicatorMenu.outerHeight() - 50);
		
		$container.find(".carousel-wrapper").css('width', $("#menu-container #menu-carousel ul li").outerWidth(true)*$("#menu-container #menu-carousel ul li").length + _globalViewportW/2);
		$("#menu-container #menu-carousel .carousel-wrapper").css({
			"padding-left": (_globalViewportW - $("#menu-container #menu-carousel ul li").outerWidth())/2,
			"padding-right": (_globalViewportW - $("#menu-container #menu-carousel ul li").outerWidth())/2
		});

		for (var i = 0; i < $("#menu-container #menu-carousel ul li").length; i++) {
		  snapPoints.push(-$("#menu-container #menu-carousel ul li").outerWidth(true) * i);
		}

		var bounds = {minX:-$("#menu-container #menu-carousel ul li").outerWidth(true)*4, maxX:0};

		Draggable.create("#menu-carousel .carousel-wrapper", {
		  	type : "x",
		  	throwProps : true,
		  	throwResistance: 100,
		  	bounds: bounds,
		  	onThrowComplete : function() {
		    	currentSlide = Math.round(Math.abs(this.endX/$("#menu-container #menu-carousel ul li").outerWidth(true)));
		    	this.applyBounds({minX:Math.max((-$("#menu-container #menu-carousel ul li").outerWidth(true)*(currentSlide+1)),lowerLimit), maxX:Math.min((-$("#menu-container #menu-carousel ul li").outerWidth(true)*(currentSlide-1)),upperLimit)});
		  	},
		  	snap:snapPoints,
		  	onPress:function() {
		    	$("#menu-carousel").addClass("holding"); 	
				},
				onRelease:function() {
					var index = giveClassActive(this);

					function giveClassActive(elem){ 
						for (var i = 0; i <= slides.length-1; i++) {
							if (Math.round(elem.endX) == Math.round(-$("#menu-container #menu-carousel ul li").outerWidth(true)*i)) { 
								$("#menu-carousel ul li").removeClass("active");
								$("#menu-carousel ul li").eq(i).addClass("active");
								$("#page-indicator-container ul li").removeClass("active");
								$("#page-indicator-container ul li").eq(i).addClass("active");
								TweenMax.to("#line", 1, {x: $("#line").width()*i, ease: Quad.easeOut});
								return i;
								
							}
						}
					}

					_canvasVideoObj.playOnMenu(index);
		  	},
		  	onDragStart:function() {
		    	_canvasVideoObj.pauseOnMenu();
		  	}
		});

		$("#page-indicator-container li").on("click", function(){
			var $this = $(this),
				elemNumber = $this.index();
			_canvasVideoObj.pauseOnMenu();
			$("#page-indicator-container ul li").removeClass("active");
			TweenMax.to("#line", 1, {x: $("#line").width()*elemNumber, ease: Quad.easeOut});
			$this.addClass("active");
			TweenMax.to("#menu-carousel .carousel-wrapper", 1, {
				x: -$("#menu-container #menu-carousel ul li").outerWidth(true)*elemNumber, 
				ease: Expo.easeInOut,
				force3D: true,
				onComplete: function() {
					_canvasVideoObj.playOnMenu(elemNumber);
				}
			});
			$("#menu-carousel ul li").removeClass("active");
			$("#menu-carousel ul li").eq(elemNumber).addClass("active");
		});

		$(".video-canvas-wrapper").css({
			"width": _globalViewportW,
			"height": _globalViewportH,
		});
	}

	$("#page-indicator-container li").on("click", function(){
		var $this = $(this),
			elemNumber = $this.index();
		_canvasVideoObj.pauseOnMenu();
		$("#page-indicator-container ul li").removeClass("active");
		TweenMax.to("#line", 1, {x: $("#line").width()*elemNumber, ease: Quad.easeOut});
		$this.addClass("active");
		TweenMax.to("#menu-carousel .carousel-wrapper", 1, {
			x: -slideWidth*elemNumber, 
			ease: Expo.easeInOut,
			force3D: true,
			onComplete: function() {
				_canvasVideoObj.playOnMenu(elemNumber);
			}
		});
		$("#menu-carousel ul li").removeClass("active");
		$("#menu-carousel ul li").eq(elemNumber).addClass("active");
	});

	$(".button-wrapper, #page-indicator-container, #header-main, .audio-helper-container").hover(
	  	function() {
	    	TweenMax.to($mouseDrag, .2, {
	      		scale:0,
	      		className: "+=hoverActive",
	      		ease: Power2.easeOut
	    	});
	  	}, function() {
	    	TweenMax.to($mouseDrag, .2, {
	      		scale:1,
	      		className: "-=hoverActive",
	      		ease: Power2.easeOut
	    	});
	  	}
	);
}

function changeMenuIndex(index){
	var slides = $("#menu-container #menu-carousel ul li"),
			slideWidth = slides.outerWidth(true);

	$("#page-indicator-container ul li").removeClass("active");
	$("#page-indicator-container ul li").eq(index).addClass("active");
	TweenMax.set("#line", {x: $("#line").width()*index});
	TweenMax.set("#menu-carousel .carousel-wrapper", {x: -slideWidth*index})
	$("#menu-carousel ul li").removeClass("active");
	$("#menu-carousel ul li").eq(index).addClass("active");
}

function menuDragFollow (e) { 
  	TweenMax.to( $("#mouse-drag"), 0.1, {
    	x: e.clientX,
    	y: e.clientY + _scrollY,
    	force3D: true,
    	ease:Linear.easeNone
  	});
}

function menuDragClick() {
	if (!$("#mouse-drag").hasClass("hoverActive")) {
		TweenMax.to( $("#mouse-drag"), 1, {
		  	scale: .8,
		  	force3D: true,
		  	className: "+=mouseDown",
		  	ease: Elastic.easeOut.config(1, .7)
		});
	}
}
function menuDragUnclick() {
	if (!$("#mouse-drag").hasClass("hoverActive")) {
		TweenMax.to( $("#mouse-drag"), 1, {
		  	scale: 1,
		  	force3D: true,
		  	className: "-=mouseDown",
	  		ease: Elastic.easeOut.config(1, .7)
		});
	}
}

/*******************************************************************************************
 **                                                                                       **
	SETUP DOM ELEMS
 **                                                                                       **
*********************************************************************************************/

function setupDomElems() {
	

	//FOOTER
	// TweenMax.set($(".next-page-image").find(".block-bg-cover"), {clip:"rect(0px "+$(".next-page-image").width()+"px 0px 0px)"});
};// end function

/*******************************************************************************************
 **                                                                                       **
		GLOBAL STICKY STUFF
 **                                                                                       **
*********************************************************************************************/
function globalStickyHeader(){
	var raf_loop_id = null;

	if(_customScroll == null) {
		/*Attach the event with a reference to kill after exit */
		var scrollRef = function() { raf_loop_id = _rAF_loop( header_scroll_rAF ); }
		$_window[0].addEventListener("scroll", scrollRef, { passive: true } );

		var status = {offset: {y : -1 }};
		status.offset.y = window.pageYOffset;
	}
	else {
		_header_scroll_listener = function(status) {
			header_scroll_rAF(status);
		};

		_customScroll.addListener(_header_scroll_listener);
	}

	function header_scroll_rAF(status) {
		var $menuContainer = $("#menu-container"),
			$scrollIndicatorMenu = $("#menu-container #page-indicator-container"),
			$zoomContainer = $(".zoom-container");

		if(_customScroll == null) {
			var status = {offset: {y : -1 }, limit: { y : _scrollLimit }, direction: {y : _scrollDirection} };
			status.offset.y = window.pageYOffset;
		}
		if ($_body.hasClass("mobile")) {
			if ($_body.hasClass("home")) {
				$(".page-header").find("video").css("top", status.offset.y+"px");
			}
		}
		if (!$_html.hasClass("ie") && !$_html.hasClass("edge")) {
			if (!$_body.hasClass("mobile")) {
				$_headerMain.css("top", status.offset.y+"px");
				$menuContainer.css("top", status.offset.y+"px");
				$scrollIndicatorMenu.css("top",status.offset.y + _globalViewportH - $scrollIndicatorMenu.outerHeight() - 50);
				$_audioHelper.css("top", status.offset.y + _globalViewportH - $_audioHelper.outerHeight() - 45);
				$_scrollHelper.css("top", status.offset.y + _globalViewportH - $_scrollHelper.outerHeight() - 45);
				$zoomContainer.css("top", status.offset.y + "px");

				if (status.offset.y > _globalViewportH/6) {
					TweenMax.to($_audioHelper, 0.4, {autoAlpha: 0, ease: Quad.easeOut});
				} else {
					TweenMax.to($_audioHelper, 0.4, {autoAlpha: 1, ease: Quad.easeOut});
				}

				if (status.offset.y > 10) {
					TweenMax.to($_scrollHelper, 0.4, {autoAlpha: 0, ease: Quad.easeOut});
				} else {
					TweenMax.to($_scrollHelper, 0.4, {autoAlpha: 1, ease: Quad.easeOut});
				}
			}

			if (_scrollDirection == 'down' && _scrollY > 0) {
				TweenMax.to(".logo-type", 1, {autoAlpha: 0, ease: Quad.easeOut});
				if (!$_body.hasClass("mobile")) {
					TweenMax.to(".logo-color", 1, {y: 28, ease: Quad.easeOut});
				} else {
					TweenMax.to($_scrollHelper, 0.4, {autoAlpha: 0, ease: Quad.easeOut});
					TweenMax.set($("#lines > span"), {backgroundColor: "#a48254" });
					TweenMax.set($(".frame-menu > polyline"), {stroke: "rgba(164,130,84, .3)" });
					// TweenMax.set($(".logo-color"), {fill: "#a48254" });
					TweenMax.to(".logo-color", .5, { fill: "rgb(164,130,84)" });
					TweenMax.to("#header-main #lang-container li .header-links", .5, { color: "rgb(164,130,84)" });
				}
				TweenMax.to($_headerMain, 1, {y: -$_headerMain.outerHeight(true)*2,force3D:true, ease: Quad.easeOut});
				$_headerMain.removeClass("scroll-up");
			} else if (_scrollY == 0 || status.offset.y == 0) {
				$_headerMain.removeClass("scroll-up");
				if (!$_body.hasClass("mobile")) {
					TweenMax.to($_headerMain, 1, {y: 20, ease: Quad.easeOut});
					TweenMax.to(".logo-color", 1, {y: -13, ease: Quad.easeOut});
					TweenMax.to(".logo-type", 1, {autoAlpha: 1, ease: Quad.easeOut});
				} else {
					TweenMax.to($_scrollHelper, 0.4, {autoAlpha: 1, ease: Quad.easeOut});
					TweenMax.to($_headerMain, 1, {y: 20, ease: Quad.easeOut});
					TweenMax.set($("#lines > span"), {backgroundColor: "#fff" });
					TweenMax.set($(".frame-menu > polyline"), {stroke: "rgba(255,255,255,1)" });
					TweenMax.set($(".logo-color"), {fill: "#fff" });
					TweenMax.set("#header-main #lang-container li .header-links", { color: "#fff" });
				}
			} else if (!$_body.hasClass("js-lightbox-open")){
				TweenMax.to($_headerMain, 1, {y: 0, ease: Quad.easeOut});
				$_headerMain.addClass("scroll-up");
			}
		} else {
			if (_scrollY > _globalViewportH/6) {
				TweenMax.to($_audioHelper, 0.4, {autoAlpha: 0, ease: Quad.easeOut});
			} else {
				TweenMax.to($_audioHelper, 0.4, {autoAlpha: 1, ease: Quad.easeOut});
			}

			if (_scrollY > 10) {
				TweenMax.to($_scrollHelper, 0.4, {autoAlpha: 0, ease: Quad.easeOut});
			} else {
				TweenMax.to($_scrollHelper, 0.4, {autoAlpha: 1, ease: Quad.easeOut});
			}

			if (_scrollDirection == 'down') {
				TweenMax.to(".logo-type", 1, {autoAlpha: 0, ease: Quad.easeOut});
				TweenMax.to(".logo-color", 1, {y: 28, ease: Quad.easeOut});
				TweenMax.to($_headerMain, 1, {y: -$_headerMain.outerHeight(true)*2,force3D:true, ease: Quad.easeOut});
				$_headerMain.removeClass("scroll-up");
			} else if (_scrollY  == 0) {
				TweenMax.to(".logo-type", 1, {autoAlpha: 1, ease: Quad.easeOut});
				TweenMax.to(".logo-color", 1, {y: -13, ease: Quad.easeOut});
				$_headerMain.removeClass("scroll-up");
				TweenMax.to($_headerMain, 1, {y: 20, ease: Quad.easeOut});
			} else if (!$_body.hasClass("js-lightbox-open")){
				TweenMax.to($_headerMain, 1, {y: 0, ease: Quad.easeOut});
				$_headerMain.addClass("scroll-up");
			}
		}
	}
}// end function


/*******************************************************************************************
 **                                                                                       **
		ENTER PAGE ANIMATION
 **                                                                                       **
*********************************************************************************************/
function headerAnimations(){
	var $title = $(".frame-title"),
		$clipVideo = $(".clip-svg"),
		$ciproLogo = $("#cipro-logo"),
		$introductionText = $(".introduction-text"),
		buttonHeight = $(".button-wrapper").outerHeight(),
		spanHeight = $(".audio-helper-container .audio-state span").height(),
		headerAnim = new TimelineMax();

	breakTitleLines($title);
	TweenMax.set($title, {opacity: 1});
	if ($_body.hasClass("four04")) {
		anime.timeline()
			.add({
				targets: '.letter',
				translateX: [0,0],
				translateY: [110, 0],
				translateZ: 0,
				scale: [0.1, 1],
				opacity: [0,1],
				easing: "easeOutQuad",
				duration: 1400,
				offset: "+=1000",
				delay: function(el, i) {
					return 500 + 50 * i;
				}
			});
		TweenMax.to(".button-wrapper", 1, {className: "+=showBtn", delay: 2.5, ease: Expo.easeOut});
		TweenMax.to(".button-wrapper .button-inner", 1, {height: buttonHeight, delay: 3,ease: Expo.easeOut});
		TweenMax.to(".discover-line-btn", 1, {opacity: 1, ease: Quad.easeOut, delay: 3.2});
		TweenMax.to(".video-wrapper h3", 1, {opacity: 0.5, ease: Quad.easeOut, delay: 3.2});
	} else {
		anime.timeline()
			.add({
				targets: '.letter',
				translateX: [0,0],
				translateY: [110, 0],
				translateZ: 0,
				scale: [0.1, 1],
				opacity: [0,1],
				easing: "easeOutQuad",
				duration: 1400,
				offset: "+=1000",
				delay: function(el, i) {
					return 500 + 50 * i;
				}
			});
	}


	if (!$_body.hasClass("home")) {
		TweenMax.to(".page-current .frame", 3.5, {scale: 1, autoAlpha:1, rotation: 90, ease: Quad.easeInOut});
	} else if ($_body.hasClass("home")) {
		$.doTimeout(2700, function(){
			anime.timeline()
				.add({
					targets: '#lang-container .header-links .char, .type-menu-container span:not(.char).type-menu .char',
					translateY: [110, 0],
					easing: "easeOutQuad",
					duration: 1400,
					offset: "-=100",
					delay: function(el, i) {
						return 500 + 50 * i;
					}
				});
		});
		headerAnim.to($ciproLogo, 1.5, {scale: 1, opacity: 1, ease: Quad.easeOut}, "+=0.7")
				.to(".button-wrapper", 1, {className: "+=showBtn", ease: Expo.easeOut}, "+=1")
				.to(".button-wrapper .button-inner", 1, {height: buttonHeight, ease: Expo.easeOut}, "-=0.5")
				.to(".discover-line-btn", 1, {opacity: 1, ease: Quad.easeOut}, "-=0.7")
				.to($introductionText, 2, {autoAlpha: 1, ease: Quad.easeOut}, "-=0.8")
				.to(".audio-wrapper h2", 0.6, {y: 0, ease: Quad.easeOut}, "-=0.5")
				.to(".audio-state", 0.6, {y: 0, ease: Quad.easeOut}, "-=0.4")
				.to(".frame-menu", 1, {opacity: 1, ease: Quad.easeOut}, "-=2.4")
				.to("#lines", 1, {scaleX: 1, ease: Quad.easeOut, onComplete: function(){
					_canClick = true;
				}}, "-=2.5");
		if (!cookie.get("audio-muted") || cookie.get("audio-muted") == 'false') {
			TweenMax.staggerTo(".pointer", 0.6, {scale: 1, force3D: true, ease: Quad.easeOut, delay: 5, onComplete: function(){
				TweenMax.to(".mini-pointer", 0.4, {scale: 1.3, yoyo:true, repeat:-1});
				TweenMax.to(".medium-pointer", 0.5, {scale: 1.3, yoyo:true, repeat:-1});
				TweenMax.to(".big-pointer", 0.6, {scale: 1.2, yoyo:true, repeat:-1});
			}}, 0.1);
		} else {
			TweenMax.to(".mini-pointer", 0.4, {scale: 1 , delay:5});
		}
	}
}

/*******************************************************************************************
 **                                                                                       **
		REACH FOOTER BACKGROUND CHANGE
 **                                                                                       **
*********************************************************************************************/
function reachFooter(){ 
	var $footer = $(".page-footer"),
		$nextPageImage = $(".next-page-image"),
		$carreersWrapper = $(".total-careers-container"),
		footerHeight = $(".page-footer").outerHeight(),
		footerAnimation = new TimelineMax(),
		buttonHeight = $(".button-wrapper").outerHeight(),
		footerOuterHeight = $footer.outerHeight();


	var animate = function() {
		if (!$_body.hasClass("mobile")) {
			if (_scrollY > _scrollLimit -  footerOuterHeight/1.5) {
				$_body.find(".page-content").addClass("black-background");
			} else {
				$_body.find(".page-content").removeClass("black-background");
			}
		}

		if (verge.inViewport($nextPageImage, -50)) {
			footerAnimation.to($(".next-page-image").find("img"), 1, {scale:1, ease: Quad.easeOut})
							.to($footer.find(".frame-footer"), 2.5, {opacity: 1, rotation: 0, ease: Quad.easeOut}, "-=0.8")
							.to(".next-page-text-wrapper h2", 2, {opacity: 1, scale: 1,  ease: Quad.easeOut}, "-=2.5")
							.to($footer.find(".button-wrapper"), 1, {className: "+=showBtn", ease: Quad.easeOut}, "-=2.5")
							.to($footer.find(".button-wrapper .button-inner"), 1, {height: buttonHeight, ease: Quad.easeOut}, "-=1.5")
							.to($footer.find(".discover-line-btn"), 1, {opacity: 1, ease: Quad.easeOut}, "-=1");
		}
	}

	return {
		animate: animate
	}

	
}

/*******************************************************************************************
 **                                                                                       **
		GLOBAL SCROLL ANIMATIONS
 **                                                                                       **
*********************************************************************************************/
function initScrollAnimations() {
	var $jsParallax = $(".js-parallax"),
		$blockBtn = $(".discover-block-btn"),
		$overlapImage = $(".overlap-image"),
		$conceptImage = $(".big-photo-container").find(".vertical-image .block-bg-cover");

	var init = function(){
		TweenMax.set($jsParallax.find("img"), {scale: 1.5, force3D: true});
		TweenMax.set($blockBtn, {clip:"rect(0px 0px "+ $blockBtn.outerHeight() +"px 0px)"});
		$overlapImage.each(function(){
			var $this = $(this);
			TweenMax.set($this, {clip:"rect(0px "+$this.outerWidth()+"px 0px 0px)"}); 
		});
	}

	var animate = function(){

		if (verge.inViewport($conceptImage, -200)) {
			TweenMax.to($conceptImage, 1, {clip:"rect(0px "+$conceptImage.outerWidth()+"px "+$conceptImage.outerHeight()+"px 0px)", ease: Quad.easeOut});
		}
		$jsParallax.each(function(){
			var $this = $(this),
				range = $this.attr("data-range");

			if (verge.inViewport($this, -150)) {
				TweenMax.to($this.find($overlapImage), 1, {clip:"rect(0px "+$this.outerWidth()+"px "+$this.find($overlapImage).outerHeight()+"px 0px)", delay: 0.1, ease: Quad.easeOut});
				TweenMax.to($this.find(".block-bg-cover .overlay-image"), 2, {scaleY: 0, ease: Quad.easeOut});
				TweenMax.to($this.find("img"), 3, {scale: 1,force3D: true, delay: 0.1, ease: Power2.easeOut});
				$.doTimeout(500, function(){
					$this.find("label").addClass("showLabel");
				});
				if (!$_body.hasClass("mobile") && !$_html.hasClass("ie") && !$_html.hasClass("edge")) {
					$this.css("transform","translateY(" +  ($this.offset().top/range)  + "px)");
				}
			}
		});
	}

	return {
		init: init,
		animate: animate
	}
}

/*******************************************************************************************
 **                                                                                       **
		GLOBAL VIEWPORT OBSERVER
 **                                                                                       **
*********************************************************************************************/
function initViewportAnimations(){
	var $mediumTitle = $(".medium-title"),
		$text = $(".text"),
		$workContainer = $(".work-container"),
		$addressesContainer = $(".addresses-container .columns"),
		$jobsCarreers = $(".jobs-careers"),
		$slideshowContainer = $(".cipro-complex-slideshow .slideshow-text-wrapper, .made-in-africa .video-text-wrapper"),
		buttonHeight = $jobsCarreers.find(".button-wrapper").outerHeight();

	$workContainer.find(".image-container .block-bg-cover img").each(function(){
		var $this = $(this);
		TweenMax.set($this, {scale: 1.4});
	});

	var options = {
		rootMargin: '0px',
		threshold: [0, 0.75]
	}

	var callback = function(entries, observer) {
		entries.forEach(function(entry) {

			if (entry.isIntersecting || entry.intersectionRatio > 0) {
				observer.unobserve(entry.target);

				switch(entry.target.type) {
					case 'titleElement' :
						if($_body.hasClass("mobile")) {
							TweenMax.to(entry.target, .7, {opacity: 1, y: 0, ease: Quad.easeOut});
						} else {
							TweenMax.to(entry.target, 1, {opacity: 1, y: 0, delay:0.5, ease: Quad.easeOut});
						}
					break;

					case 'textElement' :
						if($_body.hasClass("mobile")) {
							if (!$_body.hasClass("contacts")) {
								TweenMax.to(entry.target, .7, {opacity: 1, y: 0, ease: Quad.easeOut});
							}
						} else {
							if (!$_body.hasClass("contacts")) {
								TweenMax.to(entry.target, 1, {opacity: 1, y: 0, delay:0.7, ease: Quad.easeOut});
							}
						}
					break;

					case 'imageElement' :
						if($_body.hasClass("mobile")) {
							TweenMax.to(entry.target.overlay, .7, { scaleY: 0, ease: Quad.easeOut});
							TweenMax.to(entry.target.image, 1, { scale: 1, force3D: true, delay: 0.2, ease: Power2.easeOut});
						} else {
							TweenMax.to(entry.target.overlay, 1, { scaleY: 0, delay: 0.7, ease: Quad.easeOut});
							TweenMax.to(entry.target.image, 2, { scale: 1, force3D: true, delay: 0.8, ease: Power2.easeOut});
						}
						var sentenceWidth = Math.floor($(entry.target).closest($workContainer).find(".sentence-carousel h2").width()),
							whiteSpace = Math.floor((_globalViewportW - sentenceWidth) / 2),
							finalWidth = sentenceWidth + whiteSpace;
						if ($_body.hasClass("mobile")) {
							$.doTimeout(500, function(){
								TweenMax.to($(entry.target).closest($workContainer).find(".sentence-carousel-inside, .sentence-carousel"), 10, {x: -sentenceWidth, repeat: -1, ease: Linear.easeNone});
								TweenMax.to($(entry.target).find(".click-plus"), 1, {autoAlpha: 1, scale: 1, force3D: true, ease: Quad.easeOut});
							});
						} else {
							$.doTimeout(1500, function(){
								TweenMax.to("#mouse-follow", 0.6, {autoAlpha: 1, scale: 1, force3D: true, ease: Quad.easeOut});
								TweenMax.to($(entry.target).closest($workContainer).find(".sentence-carousel-inside, .sentence-carousel"), 15, {x: -finalWidth, repeat: -1, ease: Linear.easeNone});
								$(entry.target).closest($workContainer).addClass("isVisible");
							});
						}
					break;

					case 'imageCenterElement' :
						if($_body.hasClass("mobile")) {
							TweenMax.to(entry.target.view, .5, {className: "+=showView", delay: 1});
						} else {
							TweenMax.to(entry.target.view, 1, {className: "+=showView", delay: 1.7});
						}
					break;

					case 'slideshowElement' :
						TweenMax.to(entry.target.subtitle, 1, {scale: 1, opacity: 1, delay: 0.4, ease: Quad.easeOut});
						TweenMax.staggerTo(entry.target.mainTitle, 1, {scale:1, opacity: 1, delay: 0.8, ease: Quad.easeOut}, 0.07);
						TweenMax.to(entry.target.text, 1, {scale: 1, opacity: 1, delay: 1.2, ease: Quad.easeOut});
						TweenMax.to(entry.target.button, 1, {clip:"rect(0px "+ entry.target.btnWidth +"px "+ entry.target.btnHeight +"px 0px)", className: "+=active", delay: 1.4, onComplete: function(){
							TweenMax.to(entry.target.button, 0, {className: "-=active"});
						}});
					break;

					case 'address' :
						TweenMax.to(entry.target.country, 1, {opacity: 1, scale: 1, delay: 0.4, ease: Quad.easeOut});
						TweenMax.to(entry.target.link, 1, {opacity: 1, scale: 1, delay: 0.8, skewX: 0, force3D: true, ease: Quad.easeOut});
						TweenMax.to(entry.target.phone, 1, {opacity: 1, scale: 1, delay: 1.2, force3D: true, ease: Quad.easeOut});
					break;

					case 'jobs' :
						TweenMax.to(entry.target.frame, 1, {opacity: 1, delay: 0.8, ease: Quad.easeOut});
						TweenMax.to(entry.target.italic, 1, {skewX: 0, opacity: 1, delay: 1.2, ease: Quad.easeOut});
						TweenMax.to(entry.target.jobsTitle, 1, {opacity: 1, y: 0, force3D: true, delay: 1.6, ease: Quad.easeOut});
						TweenMax.to(entry.target.text, 1, {opacity: 1, y: 0, force3D: true, delay: 2, ease: Quad.easeOut});
						TweenMax.to(entry.target.button, 1, {className: "+=showBtn", delay: 2.4, ease: Quad.easeOut});
						TweenMax.to($jobsCarreers.find(".button-wrapper .button-inner"), 1, {height: buttonHeight, delay: 3, ease: Expo.easeOut});
						TweenMax.to($jobsCarreers.find(".discover-line-btn"), 1, {opacity: 1, delay: 3.2, ease: Quad.easeOut});
						TweenMax.to($jobsCarreers.find(".frame"), 2, {autoAlpha: 1, rotation:90, ease: Quad.easeOut});
					break;
				}
			}
		});
	}

	var observer = new IntersectionObserver(callback, options);

	$mediumTitle.each(function(index) {
		var $this = $(this);
		$this[0].type = 'titleElement';  
		observer.observe($this[0]);
	});

	$text.each(function(index) {
		var $this = $(this);
		$this[0].type = 'textElement';  
		observer.observe($this[0]);
	});

	$workContainer.find(".image-container .block-bg-cover").each(function(index) {
		var $this = $(this);
		$this[0].type = 'imageElement';  
		$this[0].overlay = $this.find(".overlay-image"); 
		$this[0].image = $this.find("img"); 
		observer.observe($this[0]);
	});

	$workContainer.find(".center-image").each(function(){
		var $this = $(this);
		$this[0].type = 'imageCenterElement';
		$this[0].view = $this.closest($workContainer).find(".pos-rel .view-project-wrapper");
		observer.observe($this[0]);
	});

	$slideshowContainer.each(function(index) {
		var $this = $(this);
		$this[0].type = 'slideshowElement'; 
		$this[0].mainTitle = $this.find("h2");  
		$this[0].subtitle = $this.find("h3");  
		$this[0].text = $this.find("p");  
		$this[0].button = $this.closest(".cipro-complex-slideshow").find(".discover-block-btn");  
		$this[0].btnWidth = $this.closest(".cipro-complex-slideshow").find(".discover-block-btn").outerWidth();  
		$this[0].btnHeight = $this.closest(".cipro-complex-slideshow").find(".discover-block-btn").outerHeight();  
		observer.observe($this[0]);
	});

	$addressesContainer.each(function(index){
		var $this = $(this);
		$this[0].type = 'address';
		$this[0].country = $this.find(".country");
		$this[0].link = $this.find(".address");
		$this[0].phone = $this.find(".telephone");
		observer.observe($this[0]);
	});

	$jobsCarreers.each(function(){
		var $this = $(this);
		$this[0].type = 'jobs';
		$this[0].frame = $this.find(".frame-blur");
		$this[0].italic = $this.find(".italic");
		$this[0].jobsTitle = $this.find(".jobs-title");
		$this[0].text = $this.find(".text");
		$this[0].button = $this.find(".button-wrapper");
		observer.observe($this[0]);
	});
}

/*******************************************************************************************
 **                                                                                       **
		LIGHTBOX CAROUSEL
 **                                                                                       **
*********************************************************************************************/

function lockBodySafari() {
  /**
    * Prevent body from scrolling on safari mobile
  **/

  var $bodyWindow = $('html, body'),
      $wrap = $('.scroll-content-wrapper');

  if(window.pageYOffset) {
    _scrollTopMobileSafariFix = window.pageYOffset;

    $wrap.css({
      top: - (_scrollTopMobileSafariFix)
    });
  }

  $bodyWindow.css({
    height: "100%",
    overflow: "hidden"
  });
}

function unlockBodySafari() {
  /**
    * Prevent body from scrolling on safari mobile
  **/

  var $bodyWindow = $('html, body'),
      $wrap = $('.scroll-content-wrapper');

  $bodyWindow.css({
    height: "",
    overflow: ""
  });

  $wrap.css({
    top: ''
  });

  window.scrollTo(0, _scrollTopMobileSafariFix);
  window.setTimeout(function () {
    _scrollTopMobileSafariFix = null;
  }, 0);
}

function lightboxCarousel($image){
	var $ligthboxContainer = $(".lightbox-container"),
		$totalSlidesContainer = $(".total-slides-container"),
		$totalSlidesNumber = $(".total-slides-number"),
		$slidesContainer = $(".slides-container"),
		$currentSlideWrapper = $(".current-slide-wrapper"),
		$workContainer = $(".work-container"),
		$imagesContainer = $(".images-container-js"),
		$ciproSlideshowContainer = $(".cipro-complex-slideshow"),
		$mouseZoom = $(".mouse-zoom"),
		$zoomContainer = $(".zoom-container"),
		$zoomWrapper = $(".zoom-wrapper"),
		$mouseFollow = $('#mouse-follow'),
		$mouseLoading = $(".mouse-loading");

	var colorThief = new ColorThief(),
		images = [], 
		imageDetails = {},
		bool = true,
		count = 1,
		elemWidth = 0,
		elemHeight = 0,
		tl = new TimelineMax();

	if (!$_html.hasClass("ie") && !$_html.hasClass("edge")) {
		TweenMax.set($ligthboxContainer, {clip: "rect(0px "+$ligthboxContainer.width()+"px 0px 0px)", autoAlpha: 0});
	}

	var init = function($image){
		var totalSlides = $image.closest($imagesContainer).find(".hidden-photos img").length,
			image = $image[0],
			color = colorThief.getColor(image),
			slideShowWidth = $(".lightbox-container .slides-container").outerWidth(),
			slideShowHeight = $(".lightbox-container .slides-container").outerHeight();

		if (!$_body.hasClass("mobile")) {
			$_window.on('resize.lightboxContainer', $.debounce(500, resize));
			TweenMax.set( $mouseZoom,{
			    x: _mouseX,
			    y: _mouseY
			});
		}

		TweenMax.to($_headerMain, 1, {y: -$_headerMain.outerHeight(true)*2, delay: 0.6, force3D:true, ease: Quad.easeOut});
		$_headerMain.removeClass("scroll-up");
		TweenMax.set($ligthboxContainer, {width: _globalViewportW, height: _globalViewportH});
		$image.closest($imagesContainer).find(".hidden-photos img").imagesLoaded(function($images, $proper, $broken) {
		    var fPreload = $(this).imagesLoaded();
		    fPreload.always(function() {
	    		TweenMax.to($mouseLoading.find("span:first-of-type"), 0.6, {scale: 0, ease: Quad.easeOut});
	    		TweenMax.to($mouseLoading.find("span:last-of-type"), 0.6, {scale: 0, delay: 0.2, ease: Quad.easeOut});
	    		TweenMax.to($ciproSlideshowContainer.find(".discover-block-btn").find("svg"), 0.6, {scale: 1, ease: Quad.easeOut});
			    $image.closest($imagesContainer).find(".hidden-photos img").each(function(){
			    	var $this = $(this),
			    		image = $this[0],
			    		source = $this.attr("src"),
			    		imageWidth = $this.width(),
			    		imageHeight = $this.height();

			    	imageDetails = {
			    		url: source,
			    		width: imageWidth,
			    		height: imageHeight
			    	}

			    	images.push(imageDetails);
			    });	

			    for (var i = 0; i <= images.length-1; i++) {
			    	if (images[i].width < images[i].height) {
			    		$slidesContainer.append("<div class='slide-image-container'><div class='block-bg-cover'><img class='slide-vertical-image' src='"+images[i].url+"'></picture></div></div>");
			    	} else if (images[i].width == images[i].height) {
			    		$slidesContainer.append("<div class='slide-image-container'><div class='block-bg-cover'><img class='slide-vertical-image' src='"+images[i].url+"'></picture></div></div>");
			    	} else {
			    		$slidesContainer.append("<div class='slide-image-container'><div class='block-bg-cover'><img class='element-cover' src='"+images[i].url+"'></picture></div></div>");
			    	}
			    }

			    for (var j = 1; j <= images.length; j++) {
			    	$currentSlideWrapper.find("ul").append("<li class='slide-number'>"+j+"</li>");
			    }

			    if (!$_html.hasClass("ie") && !$_html.hasClass("edge")) {
			    	$(".slide-image-container").each(function(){
			    		var $this = $(this);
			    		TweenMax.set($this, {clip:"rect(0px 0px "+$this.height()+"px 0px)"});
			    	});

			    	$image.closest($workContainer).find(".image-container .block-bg-cover").each(function(){
			    		var $this = $(this);
			    		TweenMax.set($this, {clip:"rect(0px "+$this.width()+"px "+$this.height()+"px 0px)"});
			    	});
			    } else {
			    	$(".slide-image-container").each(function(){
			    		var $this = $(this);
			    		TweenMax.set($this, {opacity: 0});
			    	});

			    	$image.closest($workContainer).find(".image-container .block-bg-cover").each(function(){
			    		var $this = $(this);
			    		TweenMax.set($this, {opacity: 0});
			    	});
			    }

			    $totalSlidesNumber.html(totalSlides);
			    $totalSlidesContainer.css("height", $totalSlidesNumber.height());
			    if ($_body.hasClass("mobile")) {
			    	TweenMax.set(".slides-container", {height: _globalViewportH});
			    }

			    if (!$_html.hasClass("ie") && !$_html.hasClass("edge")) {
			    	if (!$_body.hasClass("mobile")) {
			    		$ligthboxContainer.css({
			    			"background-color": "rgb("+color+")",
			    			"top": _scrollY + $image.closest($imagesContainer).offset().top - ((_globalViewportH - $image.closest($imagesContainer).outerHeight())/2) + "px",
			    			"pointer-events": "all"
			    		});
			    	} else {
			    		$ligthboxContainer.css({
			    			"background-color": "rgb("+color+")",
			    			"pointer-events": "all"
			    		});
			    	}
			    } else {
			    	$ligthboxContainer.css({
			    		"background-color": "rgb("+color+")",
			    		"pointer-events": "all"
			    	});
			    }

			    $.doTimeout(1000, function(){
			    	TweenMax.set($zoomWrapper, {
			    		width: $slidesContainer.find(".slide-image-container").eq(0).find("img").width(),
			    		height: $slidesContainer.find(".slide-image-container").eq(0).find("img").height(),
			    		y: $slidesContainer.offset().top,
			    		force3D: true
			    	});
			    });

			    if ($_body.hasClass("about")) {
			    	$ciproSlideshowContainer.find(".discover-block-btn").css("pointer-events", "none");
			    	if (!$_html.hasClass("ie") && !$_html.hasClass("edge")) {
			    		if (!$_body.hasClass("mobile")) {
				    		var initialHeight = $image.closest($ciproSlideshowContainer).find(".block-image-container").outerHeight();
			    		    _customScroll.scrollTo(0, $image.closest($ciproSlideshowContainer).offset().top + _customScroll.offset.y - ((_globalViewportH - $image.closest($ciproSlideshowContainer).outerHeight())/2), 1000, function() {
			    			  	_customScroll.unregisterEvents(/scroll/, /wheel/, /touchstart/);
			    			  	TweenMax.to($_headerMain, 0.6, {y: -$_headerMain.outerHeight(true), ease: Quad.easeOut});
			    			  	TweenMax.to($image.closest($ciproSlideshowContainer).find(".discover-block-btn"), 1.4, {y:$image.closest($ciproSlideshowContainer).find(".discover-block-btn").outerHeight()*2, ease: Expo.easeOut})
			    			  	TweenMax.to($image.closest($ciproSlideshowContainer).find(".block-image-container"), 1, {
			    			  		width: slideShowWidth,
			    			  		height: slideShowHeight,
			    			  		z: 2,
			    			  		rotationX: 10,
			    			  		transformOrigin: "center center",
			    			  		ease: Expo.easeIn,
			    			  		x: $(".lightbox-container .slides-container").offset().left, 
			    			  		y: $(".lightbox-container .slides-container").offset().top - ((_globalViewportH-$(".cipro-complex-slideshow").outerHeight())/2)-1, 
			    			  		zIndex: 101,
			    			  		onComplete: function(){
			    			  			$.doTimeout(250, function(){
			    			  				TweenMax.set($ligthboxContainer, {autoAlpha: 1, zIndex: 100});
			    			  				TweenMax.to($ligthboxContainer, 0.6, {clip: "rect(0px "+$ligthboxContainer.width()+"px "+$ligthboxContainer.height()+"px 0px)", delay: 0.1, ease: Quad.easeInOut});
			    			  				TweenMax.to($image.closest($ciproSlideshowContainer).find(".block-image-container"), 0.6, {className: "+=gradient-out", delay: 0.4, ease: Quad.easeOut})
			    			  				TweenMax.staggerTo($image.closest($ciproSlideshowContainer).find(".js-animate-element"), 0.4, {scale: 0.9, opacity: 0, delay: 0.4, ease: Quad.easeOut}, 0.1);
			    			  				TweenMax.set($image.closest($ciproSlideshowContainer), {autoAlpha: 0, zIndex: 0, delay: 0.8, onComplete:function(){
			    			  					$ligthboxContainer.css("z-index", 1001);
			    			  					TweenMax.set($image.closest($ciproSlideshowContainer), {autoAlpha: 1});
			    			  					TweenMax.set($image.closest($ciproSlideshowContainer).find(".js-animate-element"), {scale: 1, opacity:1});
			    			  					TweenMax.set($image.closest($ciproSlideshowContainer).find(".block-image-container"), {className: "-=gradient-out", width: _globalViewportW, height: initialHeight, y: 0, x: 0, z: 0, zIndex:1});
			    			  					TweenMax.set($image.closest($ciproSlideshowContainer).find(".discover-block-btn"), {y: 0});
			    			  					$image.closest($ciproSlideshowContainer).removeAttr('style');
			    			  				}});
			    			  			})
			    			  		}
			    			  	});
			    			  	TweenMax.to($image.closest($ciproSlideshowContainer).find(".block-image-container"), 1.5, {
			    			  		z: 1,
			    			  		delay: 1, 
			    			  		rotationX: 0,
			    			  		transformOrigin: "center center",
			    			  		ease: Expo.easeOut
			    			  	});
			    			  	$.doTimeout(2000, function(){
			    			  		TweenMax.set($(".slide-image-container").eq(0), {clip:"rect(0px "+$(".slide-image-container").eq(0).outerWidth()+"px "+$(".slide-image-container").eq(0).outerHeight()+"px 0px)", onComplete: function(){
			    			  			$(".slide-image-container:first-of-type").addClass("active");
			    			  			$(".slide-image-container:first-of-type").next(".slide-image-container").addClass("next");
			    			  		}});
			    			  	});
			    			});
			    		} else {
				    		var initialHeight = $image.closest($ciproSlideshowContainer).find(".block-image-container").outerHeight();
				    		TweenMax.to($_window, 1, {scrollTo: {y: $image.closest($ciproSlideshowContainer).offset().top}, ease: Expo.easeOut, onComplete: function(){
			    			  	TweenMax.to($_headerMain, 0.6, {y: -$_headerMain.outerHeight(true), ease: Quad.easeOut});
			    			  	TweenMax.to($image.closest($ciproSlideshowContainer).find(".discover-block-btn"), 1.4, {y:$image.closest($ciproSlideshowContainer).find(".discover-block-btn").outerHeight()*4, ease: Expo.easeOut})
			    			  	TweenMax.to($image.closest($ciproSlideshowContainer).find(".block-image-container"), 1, {
			    			  		width: _globalViewportW,
			    			  		height: _globalViewportH,
			    			  		z: 2,
			    			  		onComplete: function(){
			    			  			$.doTimeout(250, function(){
			    			  				TweenMax.set($ligthboxContainer, {autoAlpha: 1, zIndex: 100});
			    			  				TweenMax.to($ligthboxContainer, 0.6, {clip: "rect(0px "+$ligthboxContainer.width()+"px "+$ligthboxContainer.height()+"px 0px)", delay: 0.1, ease: Quad.easeInOut});
			    			  				TweenMax.to($image.closest($ciproSlideshowContainer).find(".block-image-container"), 0.6, {className: "+=gradient-out", delay: 0.4, ease: Quad.easeOut})
			    			  				TweenMax.staggerTo($image.closest($ciproSlideshowContainer).find(".js-animate-element"), 0.4, {scale: 0.9, opacity: 0, delay: 0.4, ease: Quad.easeOut}, 0.1);
			    			  				TweenMax.set($image.closest($ciproSlideshowContainer), {autoAlpha: 0, zIndex: 0, delay: 0.8, onComplete:function(){
			    			  					$ligthboxContainer.css("z-index", 1001);
			    			  					TweenMax.set($image.closest($ciproSlideshowContainer), {autoAlpha: 1});
			    			  					TweenMax.set($image.closest($ciproSlideshowContainer).find(".js-animate-element"), {scale: 1, opacity:1});
			    			  					TweenMax.set($image.closest($ciproSlideshowContainer).find(".block-image-container"), {className: "-=gradient-out", width: _globalViewportW, height: initialHeight, y: 0, x: 0, z: 0, zIndex:1});
			    			  					TweenMax.set($image.closest($ciproSlideshowContainer).find(".discover-block-btn"), {y: 0});
			    			  					$image.closest($ciproSlideshowContainer).removeAttr('style');
			    			  					if ($_html.hasClass("safari")) {
			    			  						lockBodySafari();
			    			  					} else {
			    			  						$("html, body").css("overflow", "hidden");
			    			  					}
			    			  				}});
			    			  			})
			    			  		}
			    			  	});
			    			  	$.doTimeout(2000, function(){
			    			  		TweenMax.set($(".slide-image-container").eq(0), {clip:"rect(0px "+$(".slide-image-container").eq(0).outerWidth()+"px "+$(".slide-image-container").eq(0).outerHeight()+"px 0px)", onComplete: function(){
			    			  			$(".slide-image-container:first-of-type").addClass("active");
			    			  			$(".slide-image-container:first-of-type").next(".slide-image-container").addClass("next");
			    			  		}});
			    			  	});
			    			}});
			    		}
			    	} else {
			    		$("html, body").css("overflow", "hidden");
			    		TweenMax.set($image.closest($ciproSlideshowContainer), {autoAlpha: 0});
			  			TweenMax.set($ligthboxContainer, {autoAlpha: 1, zIndex: 100});
			  			TweenMax.to($image.closest($ciproSlideshowContainer).find(".block-image-container"), 0.6, {className: "+=gradient-out", delay: 0.4, ease: Quad.easeOut})
			  			TweenMax.to($image.closest($ciproSlideshowContainer).find(".discover-block-btn"), 0.6, {opacity: 0, delay: 0.4, ease: Quad.easeOut})
			  			TweenMax.staggerTo($image.closest($ciproSlideshowContainer).find(".js-animate-element"), 0.4, {scale: 0.9, opacity: 0, delay: 0.4, ease: Quad.easeOut}, 0.1);
			  			TweenMax.set($image.closest($ciproSlideshowContainer), {autoAlpha: 0, zIndex: 0, delay: 0.8, onComplete:function(){
			  				$ligthboxContainer.css("z-index", 1001);
			  				TweenMax.set($image.closest($ciproSlideshowContainer), {autoAlpha: 1});
			  				TweenMax.set($image.closest($ciproSlideshowContainer).find(".js-animate-element"), {scale: 1, opacity:1});
			  				TweenMax.set($image.closest($ciproSlideshowContainer).find(".block-image-container"), {className: "-=gradient-out"});
			  				TweenMax.set($image.closest($ciproSlideshowContainer).find(".discover-block-btn"), {opacity: 1});
			  				$image.closest($ciproSlideshowContainer).removeAttr('style');
			  			}});
	    			  	TweenMax.set($(".slide-image-container").eq(0), {opacity: 1, onComplete: function(){
	    			  		$(".slide-image-container:first-of-type").addClass("active");
	    			  		$(".slide-image-container:first-of-type").next(".slide-image-container").addClass("next");
	    			  	}});
			    	}
			    }
 
			    if ($_body.hasClass("work")) {
			    	if (!$_html.hasClass("ie") && !$_html.hasClass("edge")) {
			    		if (!$_body.hasClass("mobile")) {
				    		_customScroll.scrollTo(0, $image.closest($imagesContainer).offset().top + _customScroll.offset.y - ((_globalViewportH - $image.closest($imagesContainer).outerHeight())/2), 1000, function() {
				    		  	_customScroll.unregisterEvents(/scroll/, /wheel/, /touchstart/);
				    		  	$image.closest($workContainer).find(".image-container .block-bg-cover").each(function(){
				    		  		var $this = $(this);
				    		  		TweenMax.set($this.find(".overlay-image"), {backgroundColor: "#edede4"});
				    		  		TweenMax.staggerTo($this.closest($workContainer).find(".overlay-image"), 0.4, {scaleY: 1, force3D: true, ease: Quad.easeOut}, 0.2);
				    		  		TweenMax.to($image.closest($workContainer).find(".sentence-carousel-inside, .sentence-carousel"), 0.6, {autoAlpha: 0, ease: Quad.easeIn});
				    		  		TweenMax.to($mouseFollow, 0.6, {scale: 0, force3D: true, ease: Quad.easeOut});
				    		  		TweenMax.to($this.closest($workContainer).find(".view-project-wrapper"), 0, {className: "-=showView", delay: 0.8});
				    		  		$.doTimeout(250, function(){
				    		  			TweenMax.set($ligthboxContainer, {autoAlpha: 1});
				    		  			TweenMax.to($ligthboxContainer, 0.6, {clip: "rect(0px "+$ligthboxContainer.width()+"px "+$ligthboxContainer.height()+"px 0px)", delay: 0.1, ease: Quad.easeInOut});
				    		  			TweenMax.to($(".slide-image-container:first-of-type"), 0.6, {clip:"rect(0px "+$(".slide-image-container:first-of-type").width()+"px "+$(".slide-image-container:first-of-type").height()+"px 0px)", ease: Quad.easeInOut, delay:0.6, onComplete: function(){
				    		  					$(".slide-image-container:first-of-type").addClass("active");
				    		  					$(".slide-image-container:first-of-type").next(".slide-image-container").addClass("next");
				    		  					TweenMax.set($image.closest($workContainer).find(".sentence-carousel-inside, .sentence-carousel"), {autoAlpha: 1});
				    		  					$image.closest($workContainer).find(".image-container .block-bg-cover .overlay-image").each(function(){
				    		  						var $this = $(this);
				    		  						TweenMax.set($this, {scaleY: 0});
				    		  					});
				    		  					$image.closest($workContainer).find(".view-project-wrapper").addClass("showView");
				    		  					TweenMax.set($mouseFollow, {scale: 1, force3D: true});
				    		  					if ($_body.hasClass("mobile")) {
				    		  						TweenMax.set($image.closest($workContainer).find(".click-plus"), {scale: 1});
				    		  					}
				    		  				}});
				    		  		});
				    		  	});
				    		}); 
			    		} else {
			    		  	$image.closest($workContainer).find(".image-container .block-bg-cover").each(function(){
			    		  		var $this = $(this);
			    		  		TweenMax.set($this.find(".overlay-image"), {backgroundColor: "#edede4"});
			    		  		TweenMax.staggerTo($this.closest($workContainer).find(".overlay-image"), 0.4, {scaleY: 1, force3D: true, ease: Quad.easeOut}, 0.2);
			    		  		TweenMax.to($mouseFollow, 0.6, {scale: 0, force3D: true, ease: Quad.easeOut});
			    		  		TweenMax.to($this.closest($workContainer).find(".view-project-wrapper"), 0, {className: "-=showView", delay: 0.8});
			    		  		$.doTimeout(250, function(){
			    		  			TweenMax.set($ligthboxContainer, {autoAlpha: 1});
			    		  			TweenMax.to($ligthboxContainer, 0.6, {clip: "rect(0px "+$ligthboxContainer.width()+"px "+$ligthboxContainer.height()+"px 0px)", delay: 0.1, ease: Quad.easeInOut});
			    		  			TweenMax.to($(".slide-image-container:first-of-type"), 0.6, {clip:"rect(0px "+$(".slide-image-container:first-of-type").width()+"px "+$(".slide-image-container:first-of-type").height()+"px 0px)", ease: Quad.easeInOut, delay:0.6, onComplete: function(){
			    		  					$(".slide-image-container:first-of-type").addClass("active");
			    		  					$(".slide-image-container:first-of-type").next(".slide-image-container").addClass("next");
			    		  					$image.closest($workContainer).find(".image-container .block-bg-cover .overlay-image").each(function(){
			    		  						var $this = $(this);
			    		  						TweenMax.set($this, {scaleY: 0});
			    		  					});
			    		  					$image.closest($workContainer).find(".view-project-wrapper").addClass("showView");
			    		  					TweenMax.set($mouseFollow, {scale: 1, force3D: true});
			    		  					if ($_body.hasClass("mobile")) {
			    		  						TweenMax.set($image.closest($workContainer).find(".click-plus"), {scale: 1});
			    		  					}
			    		  					if ($_html.hasClass("safari")) {
			    		  						lockBodySafari();
			    		  					} else {
			    		  						$("html, body").css("overflow", "hidden");
			    		  					}
			    		  				}});
			    		  		});
			    		  	});
			    		}
			    	} else {
			    		TweenMax.to($_window, 1, {scrollTo: {y: $image.closest($imagesContainer).offset().top}, ease: Expo.easeOut, onComplete: function(){
			    			$("html, body").css("overflow", "hidden");
			    			$image.closest($workContainer).find(".image-container .block-bg-cover").each(function(){
			    				var $this = $(this);
			    				TweenMax.set($this.find(".overlay-image"), {backgroundColor: "#edede4"});
			    				TweenMax.staggerTo($this.closest($workContainer).find(".overlay-image"), 0.4, {scaleY: 1, force3D: true, ease: Quad.easeOut}, 0.2);
			    				TweenMax.to($image.closest($workContainer).find(".sentence-carousel-inside, .sentence-carousel"), 0.6, {autoAlpha: 0, ease: Quad.easeIn});
			    				TweenMax.to($mouseFollow, 0.6, {scale: 0, force3D: true, ease: Quad.easeOut});
			    				TweenMax.to($this.closest($workContainer).find(".view-project-wrapper"), 0, {className: "-=showView", delay: 0.8, onComplete: function(){
			    					$.doTimeout(700, function(){
			    						TweenMax.set($ligthboxContainer, {autoAlpha: 1});
			    						TweenMax.to($ligthboxContainer, 0.6, {opacity: 1, delay: 0.1, ease: Quad.easeInOut});
			    						TweenMax.to($(".slide-image-container:first-of-type"), 0.6, {opacity: 1, ease: Quad.easeInOut, delay:0.6, onComplete: function(){
			    								$(".slide-image-container:first-of-type").addClass("active");
			    								$(".slide-image-container:first-of-type").next(".slide-image-container").addClass("next");
			    								TweenMax.set($image.closest($workContainer).find(".sentence-carousel-inside, .sentence-carousel"), {autoAlpha: 1});
			    								$image.closest($workContainer).find(".image-container .block-bg-cover .overlay-image").each(function(){
			    									var $this = $(this);
			    									TweenMax.set($this, {scaleY: 0});
			    								});
			    								$image.closest($workContainer).find(".view-project-wrapper").addClass("showView");
			    								TweenMax.set($image.closest($workContainer).find(".block-bg-cover"), {opacity:1});
			    								TweenMax.set($mouseFollow, {scale: 1, force3D: true});
			    								if ($_body.hasClass("mobile")) {
			    									TweenMax.set($this.find(".click-plus"), {scale: 1});
			    								}
			    							}});
			    					});
			    				}});
			    			});
			    		}});
			    	}
			    }
			    $(document).on("keydown.lightboxCarousel", function(event) {
			        switch (event.which) {
			        	case 40: //down
			        	    return false;
			        	    break;
			        	case 38: //up
			        	    return false;
			        	    break;
			            case 39: //next
			            	if (!$_body.hasClass("isZoom")) {
			            		clickArrow('right');
			            	}
			                break;
			            case 37: //prev
				            if (!$_body.hasClass("isZoom")) {
				            	clickArrow('left');
				            }
			                break;
			            case 27: //close (esc)
				            if (!$_body.hasClass("isZoom")) {
				            	close();
				            } else {
				            	closeZoom();
				            }
			                break;
			            default:
			                break;
			        }
			    });
		        
			    TweenMax.to($mouseZoom, 1, {autoAlpha: 1, ease: Quad.easeOut});
			    if (!$_body.hasClass("mobile")) {
			    	$_window.on('mousemove.Zoom', moveZoom);
			    	function moveZoom (e) { 
			    	  TweenMax.to( $mouseZoom, 0.1, {
			    	    x: e.clientX,
			    	    y: e.clientY,
			    	    force3D: true,
			    	    ease:Linear.easeNone
			    	  });
			    	}
			    } else {
					$slidesContainer.on("swiperight", function(e, direction) {
						clickArrow('left');
					});
					$slidesContainer.on("swipeleft", function(e, direction) {
						clickArrow('right');
					});
					$slidesContainer.on("swipedown", function(e, direction) {
						close();
					});

			    }
		    });
		});
	}

	var clickArrow = function(direction){
		$slide = $(".slide-image-container");
		$_body.addClass("isAnimating");
		if (direction == 'right' && bool) {
			var $image = $(".slide-image-container.next").find("img"),
				image = $image[0],
				color = colorThief.getColor(image),
				easeLine;

			if ($_html.hasClass("safari")) {
				easeLine = Power2.easeOut;
			} else {
				easeLine = Expo.easeInOut;
			}
			bool = false;
			TweenMax.to($(".slide-image-container.next").find(".block-bg-cover"), 1, {backgroundColor: "rgb("+color+")", force3D: true, ease: Expo.easeOut});
			TweenMax.to($ligthboxContainer, 1, {backgroundColor: "rgb("+color+")", delay: 0.6, force3D: true, ease: Expo.easeOut});
			if (!$_html.hasClass("ie") && !$_html.hasClass("edge")) {
				TweenMax.to(".slide-image-container.next", 1.5, {clip:"rect(0px "+$slide.width()+"px "+$slide.height()+"px 0px)", ease:easeLine, force3D: true, onComplete:function(){
					count++;
				  	if (count <= images.length) {
				  		TweenMax.to($currentSlideWrapper, 0.4, {y: "+="+(-$currentSlideWrapper.find(".slide-number").outerHeight()), ease: Quad.easeOut});
				  	} else {
				  		count = 1;
				  		TweenMax.to($currentSlideWrapper, 0.4, {y: 0, ease: Quad.easeOut});
				  	}
				  	$slideActive = $(".slide-image-container.active");
				  	$slideNext = $(".slide-image-container.next");
				  	TweenMax.set($slideActive, {clip:"rect(0px 0px "+$slideActive.height()+"px 0px)"});
					$slideActive.removeClass("active");
					$slideNext.removeClass("next").addClass("active");
					if ($slideNext.next().is(".slide-image-container")) {
						$slideNext.next(".slide-image-container").addClass("next");
					} else {
						$(".slide-image-container").eq(0).addClass("next");
					}
					TweenMax.set($(".slide-image-container").find(".block-bg-cover"), {backgroundColor: "none", onComplete: function(){
						bool = true;
					}});
					TweenMax.set($zoomWrapper, {
						width: $slidesContainer.find(".slide-image-container.active").find("img").width(),
						height: $slidesContainer.find(".slide-image-container.active").find("img").height()
					});
					$_body.removeClass("isAnimating");
				}});
			} else {
				TweenMax.to(".slide-image-container.next", 1.5, {opacity: 1, ease:Expo.easeInOut, onComplete:function(){
					count++;
				  	if (count <= images.length) {
				  		TweenMax.to($currentSlideWrapper, 0.4, {y: "+="+(-$currentSlideWrapper.find(".slide-number").outerHeight()), ease: Quad.easeOut});
				  	} else {
				  		count = 1;
				  		TweenMax.to($currentSlideWrapper, 0.4, {y: 0, ease: Quad.easeOut});
				  	}
				  	$slideActive = $(".slide-image-container.active");
				  	$slideNext = $(".slide-image-container.next");
				  	TweenMax.set($slideActive, {opacity: 0, onComplete: function(){
				  		bool = true;
				  	}});
					$slideActive.removeClass("active");
					$slideNext.removeClass("next").addClass("active");
					if ($slideNext.next().is(".slide-image-container")) {
						$slideNext.next(".slide-image-container").addClass("next");
					} else {
						$(".slide-image-container").eq(0).addClass("next");
					}
					TweenMax.set($zoomWrapper, {
						width: $slidesContainer.find(".slide-image-container.active").find("img").width(),
						height: $slidesContainer.find(".slide-image-container.active").find("img").height()
					});
					$_body.removeClass("isAnimating");
				}});
			}
		} else if (direction == 'left' && bool) {
			var $image,
				image,
				color,
				easeLine;

			if ($_html.hasClass("safari")) {
				easeLine = Power2.easeOut;
			} else {
				easeLine = Expo.easeInOut;
			}

			if ($(".slide-image-container.active").prev().is(".slide-image-container")) {
				$image = $(".slide-image-container.active").prev().find("img");
				image = $image[0],
				color = colorThief.getColor(image);
				TweenMax.to($(".slide-image-container.active").prev().find(".block-bg-cover"), 1, {backgroundColor: "rgb("+color+")", delay: 0.2, ease: Expo.easeOut});
			} else {
				$image = $(".slide-image-container").last().find("img");
				image = $image[0],
				color = colorThief.getColor(image);
				TweenMax.to($(".slide-image-container").last().find(".block-bg-cover"), 1,  {backgroundColor: "rgb("+color+")", delay: 0.2, ease: Expo.easeOut});
			}

			bool = false;
			TweenMax.to($ligthboxContainer, 1, {backgroundColor: "rgb("+color+")", delay: 0.6, ease: Expo.easeOut});
			if (!$_html.hasClass("ie") && !$_html.hasClass("edge")) {
				if ($(".slide-image-container.active").prev().is(".slide-image-container")) {
					TweenMax.set($(".slide-image-container.active").prev(), {clip:"rect(0px "+$(".slide-image-container.active").prev().width()+"px "+$(".slide-image-container.active").prev().height()+"px 0px)"});
				} else {
					TweenMax.set($(".slide-image-container").last(), {clip:"rect(0px "+$(".slide-image-container").last().width()+"px "+$(".slide-image-container").last().height()+"px 0px)"});
				}
				TweenMax.to(".slide-image-container.active", 1.5, {clip:"rect(0px 0px "+$slide.height()+"px 0px)", ease:easeLine, onComplete:function(){
					count--;
				  	if (count < 1) {
				  		count = images.length;
				  		TweenMax.to($currentSlideWrapper, 0.4, {y: -$currentSlideWrapper.find(".slide-number").outerHeight()*(images.length-1), ease: Quad.easeOut});
				  	} else {
				  		TweenMax.to($currentSlideWrapper, 0.4, {y: "+="+$currentSlideWrapper.find(".slide-number").outerHeight(), ease: Quad.easeOut});
				  	}
				  	$slideActive = $(".slide-image-container.active");
				  	$slideNext = $(".slide-image-container.next");
				  	if ($slideActive.prev().is(".slide-image-container")) {
				  		$slideActive.prev(".slide-image-container").addClass("active");
				  	} else {
				  		$(".slide-image-container").last().addClass("active");
				  	}
					$slideActive.removeClass("active").addClass("next");
					$slideNext.removeClass("next");
					bool = true;
					TweenMax.set($zoomWrapper, {
						width: $slidesContainer.find(".slide-image-container.active").find("img").width(),
						height: $slidesContainer.find(".slide-image-container.active").find("img").height()
					});
					$_body.removeClass("isAnimating");
				}});
			} else {
				if ($(".slide-image-container.active").prev().is(".slide-image-container")) {
					TweenMax.set($(".slide-image-container.active").prev(), {opacity: 1});
				} else {
					TweenMax.set($(".slide-image-container").last(), {opacity: 1});
				}
				TweenMax.to(".slide-image-container.active", 1.5, {opacity: 0, ease:Expo.easeInOut, onComplete:function(){
					count--;
				  	if (count < 1) {
				  		count = images.length;
				  		TweenMax.to($currentSlideWrapper, 0.4, {y: -$currentSlideWrapper.find(".slide-number").outerHeight()*(images.length-1), ease: Quad.easeOut});
				  	} else {
				  		TweenMax.to($currentSlideWrapper, 0.4, {y: "+="+$currentSlideWrapper.find(".slide-number").outerHeight(), ease: Quad.easeOut});
				  	}
				  	$slideActive = $(".slide-image-container.active");
				  	$slideNext = $(".slide-image-container.next");
				  	if ($slideActive.prev().is(".slide-image-container")) {
				  		$slideActive.prev(".slide-image-container").addClass("active");
				  	} else {
				  		$(".slide-image-container").last().addClass("active");
				  	}
					$slideActive.removeClass("active").addClass("next");
					$slideNext.removeClass("next");
					TweenMax.set($zoomWrapper, {
						width: $slidesContainer.find(".slide-image-container.active").find("img").width(),
						height: $slidesContainer.find(".slide-image-container.active").find("img").height()
					});
					bool = true;
					$_body.removeClass("isAnimating");
				}});
			}
		}
	}

	var close = function(){
		$ciproSlideshowContainer.find(".discover-block-btn").css("pointer-events", "all");
		if (!$_body.hasClass("mobile")) {
			$zoomContainer.off('mousemove.lightboxContainer');
		} else {
			if ($_html.hasClass("safari")) {
				unlockBodySafari();
			} else {
				$("html, body").css("overflow", "visible");
			}
		}
		TweenMax.to($ligthboxContainer, 0.2, {autoAlpha: 0, ease: Quad.easeOut, onComplete: function(){
			$_body.removeClass("js-lightbox-open");
			images = [];
			$slidesContainer.html(' ');
			count = 0;
			TweenMax.to($currentSlideWrapper, 0.6, {y: 0, ease: Quad.easeOut});
			$currentSlideWrapper.find("ul").html('');
			if (!$_html.hasClass("ie") && !$_html.hasClass("edge")) {
				TweenMax.to($ligthboxContainer, 0, {clip: "rect(0px "+$ligthboxContainer.width()+"px 0px 0px)"});
				if (!$_body.hasClass("mobile")) {
					_customScroll.registerEvents(/scroll/, /wheel/, /touchstart/);
				}
			} else {
				$("html, body").css("overflow", "visible");
			}
		}});
	}

	var zoom = function(parent){
		var elem = $(parent).find(".slide-image-container.active .block-bg-cover").html(),
			elemSource = $(parent).find(".slide-image-container.active .block-bg-cover img").attr("src");

		elemWidth = $(parent).find(".slide-image-container.active .block-bg-cover img").width();
		elemHeight = $(parent).find(".slide-image-container.active .block-bg-cover img").height();

		$_body.addClass("isZoom");
		TweenMax.to($mouseZoom, 0.3, {rotation: 45, ease: Quad.easeOut});
		$zoomContainer.append($mouseZoom);
		$zoomWrapper.css({
			"background-image": "url("+elemSource+")",
			"background-size": "cover",
			"background-repeat": "no-repeat",
			"background-position": "center center"
		});
		if (elemWidth < elemHeight || elemWidth == elemHeight) {
			// TweenMax.set( $zoomContainer.find($zoomWrapper),{
   //        		y: -_mouseY / 1.1
   //      	});
			// $zoomWrapper.css({
			// 	"height": _globalViewportH*2,
			// 	"width": _globalViewportW
			// });
			TweenMax.to($zoomContainer, 0.2, {autoAlpha: 1});
			TweenMax.to($zoomContainer.find($zoomWrapper),1.5, {y:-_globalViewportH/2, width:_globalViewportW, height:_globalViewportH*2, force3D: true, ease: Power2.easeOut, onComplete: function(){
				$zoomContainer.on('mousemove.lightboxContainer', zoomImage);
			}});
		}
		if (elemWidth > elemHeight ) {
			// TweenMax.set( $zoomContainer.find($zoomWrapper),{
   //        		x: -_mouseX/2,
   //        		y: -_mouseY/2
   //      	});
			// $zoomWrapper.css({
			// 	"width": _globalViewportW*1.5,
			// 	"height": _globalViewportH*1.5
			// });
			TweenMax.set($zoomContainer, {autoAlpha: 1});
			TweenMax.to($zoomContainer.find($zoomWrapper),1.5, {x:-(_globalViewportW*0.5)/2 ,y:-(_globalViewportH*0.5)/2, width:_globalViewportW*1.5, height:_globalViewportH*1.5, force3D: true, ease: Power2.easeOut, onComplete: function(){
				$zoomContainer.on('mousemove.lightboxContainer', zoomImage);
			}});
		}
	}

	var closeZoom = function(){

		$ligthboxContainer.append($mouseZoom);
		TweenMax.to($mouseZoom, 1, {rotation: 0, ease: Quad.easeOut});
		$zoomWrapper.css({
			"background-image": "none",
			"width": $slidesContainer.find(".slide-image-container.active").find("img").width(),
			"height": $slidesContainer.find(".slide-image-container.active").find("img").height()
		});
		TweenMax.set($zoomContainer, {autoAlpha: 0});
		TweenMax.set($zoomWrapper, {y: $slidesContainer.offset().top, x: 0, force3D: true, onComplete: function(){
			$.doTimeout(1500, function(){
				$_body.removeClass("isZoom");
			});
		}});
		$zoomContainer.off('mousemove.lightboxContainer');
	}

	function zoomImage (e) { 
		if (elemWidth < elemHeight || elemWidth == elemHeight) {
			TweenMax.to($zoomWrapper, 0.9, {y: -e.clientY/1.1, force3D: true, ease: Quad.easeOut});
		} 
		if (elemWidth > elemHeight ) {
			TweenMax.to($zoomWrapper, 0.9, {x: -e.clientX/2, y: -e.clientY/2, force3D: true, ease: Quad.easeOut});
		}
	}

	function resize(){
		if (!$_body.hasClass("isZoom")) {
			TweenMax.set($zoomWrapper, {
				width: $slidesContainer.find(".slide-image-container.active").find("img").width(),
				height: $slidesContainer.find(".slide-image-container.active").find("img").height(),
				y: $slidesContainer.offset().top
			});
		} else {
			if (elemWidth < elemHeight || elemWidth == elemHeight) {
				TweenMax.set($zoomContainer.find($zoomWrapper), {y:-_globalViewportH/2, width:_globalViewportW, height:_globalViewportH*2});
			}
			if (elemWidth > elemHeight ) {
				TweenMax.to($zoomContainer.find($zoomWrapper), {x:-(_globalViewportW*0.5)/2 ,y:-(_globalViewportH*0.5)/2, width:_globalViewportW*1.5, height:_globalViewportH*1.5});
			}
		}
		
		TweenMax.set($ligthboxContainer, {width: _globalViewportW, height: _globalViewportH});
		TweenMax.set($ligthboxContainer, {clip: "rect(0px "+_globalViewportW+"px "+_globalViewportH+"px 0px)"});
	}

	var kill = function() {
		$(document).off("keydown.lightboxCarousel");
		$_window.off('resize.lightboxContainer');
		$zoomContainer.off('mousemove.lightboxContainer');
		$_window.off('mousemove.Zoom');
	}

	return {
		init: init,
		clickArrow: clickArrow, 
		close: close,
		closeZoom: closeZoom,
		zoom: zoom,
		kill: kill
	}
}


/*******************************************************************************************
 **                                                                                       **
	 PAGE SOUNDS
 **                                                                                       **
*********************************************************************************************/

function initAudios(args) {

	if($_body.hasClass("mobile")) return null;

  var main_sound = null,
      default_volume = args.defaultVolume,
      default_fade_time = args.fadeTime,
      audio_file = args.audioFile,
      sec_audio_file = args.secAudioFile,
      muted = false,
      header_sound = null,
      scroll_sound = null,
      current_volume = default_volume;

 	var cache_about = new Howl({
  	src: ['/public/video/audio/about_sprite.mp3'],
  	autoplay: false,
  	loop: false,
  	volume: 0
  });
  var cache_about = new Howl({
  	src: ['/public/video/audio/concept_sprite.mp3'],
  	autoplay: false,
  	loop: false,
  	volume: 0
  });
  var cache_about = new Howl({
  	src: ['/public/video/audio/contacts_sprite.mp3'],
  	autoplay: false,
  	loop: false,
  	volume: 0
  });
  var cache_about = new Howl({
  	src: ['/public/video/audio/home.mp3'],
  	autoplay: false,
  	loop: false,
  	volume: 0
  });
  var cache_about = new Howl({
  	src: ['/public/video/audio/work_sprite.mp3'],
  	autoplay: false,
  	loop: false,
  	volume: 0
  });

  var init = function() {
  	if(cookie.get('audioMute') == 'true')
	    muted = true;



	  main_sound = new Howl({
	    src: [audio_file],
	    sprite: args.sprite
	  });
	 	main_sound.volume(0);
	 	main_sound.loop(true); 

	 	
	 	if(args.sprite) {
	 		header_sound = main_sound.play('header');
  		scroll_sound = main_sound.play('scroll');
	 	}
	 	else {
	 		header_sound = main_sound.play();
	 	}

		if(!muted)
			fadeIn();
	
	 	addEvents();

  }

  var kill = function() {
  	main_sound.fade(main_sound.volume(), 0, default_fade_time);
  	
    $.doTimeout(default_fade_time, function() {
      main_sound.stop();
    });

    $_window.off("blur.audios");
    $_window.off("focus.audios");
  }

  var getDefaultVolume = function() {
    return current_volume;
  }

  var setVolume = function(new_volume) {
    if(new_volume < 0)
      new_volume = 0;

    main_sound.volume(new_volume);
  }
  var mute = function(type) {

    if(type) {
      fadeOut();
      muted = true;
      cookie.set('audioMute', "true");
    }
    else {
      muted = false;
      fadeIn();
      cookie.set('audioMute', "false");
    }
  }

  var play = function() {
    main_sound.volume(0);
    main_sound.play();

    fadeIn();

  }
  var stop = function() {
    main_sound.volume(0);
    main_sound.stop();
  }

  var pause = function(type) { 

    if(type) {
      main_sound.pause();
    }
    else {
      fadeOut();

      $.doTimeout(default_fade_time, function() {
        main_sound.pause();
      });
    }
  }

  var fadeOut = function(type) {
    if(!muted){
    	if(type == 'scroll')
    		main_sound.fade(main_sound.volume(), 0, default_fade_time, header_sound);
    	else {

    		if(_scrollY > _globalViewportH)
	    		main_sound.fade(main_sound.volume(), 0, default_fade_time, scroll_sound);
	    	else
	    		main_sound.fade(main_sound.volume(), 0, default_fade_time);

    		
    	}
    }
  }

  var fadeIn = function(type) {
    if(!muted){
    	if(type == 'scroll')
    		main_sound.fade(main_sound.volume(), current_volume, default_fade_time, header_sound);
    	else {
    		if(_scrollY > _globalViewportH)
	    		main_sound.fade(main_sound.volume(), current_volume, default_fade_time, scroll_sound);
	    	else{
	    		//main_sound.play();
	    		//main_sound.volume(1);
	    		main_sound.fade(main_sound.volume(), current_volume, default_fade_time);
	    	}
    	}
    	
    }
  }

  var fadeTo = function(value) {
  	if(!muted) {
  		current_volume = value;

  		if(_scrollY > _globalViewportH)
    		main_sound.fade(main_sound.volume(), value, default_fade_time, scroll_sound);
    	else{
    		main_sound.fade(main_sound.volume(), value, default_fade_time, scroll_sound);
    		main_sound.fade(main_sound.volume(), value, default_fade_time);
    	}
  	}
  }

  //Object functions
  function addEvents() {
    $_window.on("blur.audios", function() {
      fadeOut();
    });
    $_window.on("focus.audios", function() {
      fadeIn();
    });
  }
  
  return {
  	init: init,
    kill: kill,
    fadeOut: fadeOut,
    fadeIn: fadeIn,
    fadeTo: fadeTo,
    getDefaultVolume: getDefaultVolume,
    setVolume: setVolume,
    mute: mute,
    play: play,
    stop: stop,
    pause: pause
  }
}


function tickSound() {
	var $btns = $(".audio-btn"),
			hover_sound = null,
			click_sound = null,
			transition_sound = null;

	
	var init = function() {

		hover_sound = new Howl({
	    src: ['/public/audio/hover.mp3']
	  });
	  click_sound = new Howl({
	    src: ['/public/audio/click.mp3']
	  });
	  transition_sound = new Howl({
	    src: ['/public/audio/transition.mp3']
	  });

	  hover_sound.volume(.3);
	  if ($_body.hasClass("mobile")) {
	  	click_sound.volume(.1);
	  } else {
	  	click_sound.volume(.3);
	  }
	  transition_sound.volume(.8);

		$btns.on("mouseenter.tickSound", function() {
			hover_sound.play();
		});
		$btns.on("click.tickSound", function() {
			click_sound.play();
		});
	}


	var transition = function() {
		transition_sound.play();
	}

	var kill = function() {
		tick_sound = null;
		$btns.off("mouseover.tickSound");
		$btns.off("click.tickSound");
	}

	init();
	
	return {
		init: init,
		transition: transition,
		kill: kill
	}
}

/*******************************************************************************************
 **                                                                                       **
	 AUDIO HELPER
 **                                                                                       **
*********************************************************************************************/
function audioHelper(){
	var $audioContainer = $(".audio-helper-container");

	var init = function() {

		if(cookie.get('audioMute') == 'true') {
			$audioContainer.addClass("active"); 
			TweenMax.to(".mini-pointer", 0.4, {scale: 1});
			TweenMax.to(".medium-pointer", 0.5, {scale: 0});
			TweenMax.to(".big-pointer", 0.6, {scale: 0});
		}
		else {
			$audioContainer.removeClass("active");
			TweenMax.to(".mini-pointer", 0.4, {scale: 1.3, yoyo:true, repeat:-1});
		    TweenMax.to(".medium-pointer", 0.5, {scale: 1.3, yoyo:true, repeat:-1});
		    TweenMax.to(".big-pointer", 0.6, {scale: 1.2, yoyo:true, repeat:-1});
		}

	}
	
	var on = function(){
		$audioContainer.removeClass("active");
		_audioObj.mute(false);

		TweenMax.to(".mini-pointer", 0.4, {scale: 1.3, yoyo:true, repeat:-1});
	    TweenMax.to(".medium-pointer", 0.5, {scale: 1.3, yoyo:true, repeat:-1});
	    TweenMax.to(".big-pointer", 0.6, {scale: 1.2, yoyo:true, repeat:-1});
	}

	var off = function(){
		$audioContainer.addClass("active");
		_audioObj.mute(true);

		TweenMax.to(".mini-pointer", 0.4, {scale: 1});
		TweenMax.to(".medium-pointer", 0.5, {scale: 0});
		TweenMax.to(".big-pointer", 0.6, {scale: 0});
	}

	$audioContainer.on("click", function(){
    var $this = $(this),
  	index = $(".page-main.page-current .page-toload").attr("data-titlePage");

    if (!$this.hasClass("active")) {
			off();
    } 
   	else {
      on();
    }
	});

	init();

	return{
		on: on,
		off: off
	}
}

function featuringList($title){
	if($title.length <= 0) return;

	var $dash = $(".dash"),
	    $newTitle = $title,
	    width = 0;

	$dash.each(function(){
		var $this = $(this),
			text = $this.html();
		$this.remove();
	});

	$(".list span:not(.dash)").each(function(){
		var $this = $(this);
		$this.append("<span class='dash'> — </span>");
	});

	$title.find(".dash").last().detach();
	$title.find("span").each(function(){
		var $this = $(this);
		width = Math.floor(width + $this.outerWidth());
	});

	TweenMax.set($title, {x: _globalViewportW});
	$.doTimeout(1500, function(){
		TweenMax.to($(".list"), 25, {x: -width, repeat: -1, force3D: true, ease: Linear.easeNone});
	});
}

function credits(){
	var $trigger = $(".trigger-credits"),
		$lightbox = $(".credits-lightbox");


	var init = function(){
		$_window.on("resize.Credits", $.debounce(500,resizeCredits));
		function resizeCredits(){
			TweenMax.set($lightbox, {x: $trigger.offset().left});
		}
		if ($_body.hasClass("mobile")) {
			var menuOpened = 0,
				text = $(".credits-name").text(),
				closeText = $(".type-close").text();

			$(".trigger-credits").on("click", function(){
				if(menuOpened == 0) {
					TweenMax.to(".credits-lightbox", 0.6, { autoAlpha: 1, ease: Expo.easeOut});
					$(".credits-name").html(closeText);
					menuOpened = 1;
				} else {
					TweenMax.to(".credits-lightbox", 0.6, { autoAlpha: 0, ease: Expo.easeOut});
					$(".credits-name").html(text);
					menuOpened = 0;
				}
			});
		}
		
		if (!$_body.hasClass("mobile")) {
			TweenMax.set($lightbox, {x: $trigger.offset().left});
			$(".trigger-credits").hover(function(){
				TweenMax.to(".credits-lightbox", 0.6, { autoAlpha: 1, ease: Expo.easeOut});
			}, function(){
				TweenMax.to(".credits-lightbox", 0.6, { autoAlpha: 0, ease: Expo.easeOut});
			});
		}
	}

	var kill = function(){
		$_window.off("resize.Credits");
	}

	return{
		init: init,
		kill: kill
	}
}

function canvasHeaderVideo() { 
	var $scrollContent = $(".scroll-content"),
			$carouselWrapper = $(".carousel-wrapper"),
			$headerVideoWrapper = $(".page-header .video-wrapper"),
			$headerInfoWrapper = $(".page-header .header-info-wrapper"),
			$headerMaskVideoContainer = $(".page-header .frame"),
			playingVideo = null;
     	$headerCanvasWrapper = $(".page-header .video-canvas-wrapper"),
  		$headerCanvas = $headerCanvasWrapper.find("canvas"),
  		canvas_playingVideo = $headerCanvas[0],
  		ctx_canvas_playingVideo = canvas_playingVideo.getContext("2d"),
      canvas_dest_width = _globalViewportW,
      canvas_dest_height = _globalViewportH,
      res = null,
      cw = null,
      ch = null,
      raf_video_id = null,
      menu_video_array = {active : {}, all: [], activePageCanvas : {}},
      can_animate = true,
      isImageCover = false;


  if($_html.hasClass("ie") || $_html.hasClass("edge"))
  	$scrollContent = $(".scroll-content-wrapper");

	var init = function() {

		//Create the objects array to control the menu
		$carouselWrapper.find("li").each(function(index) {
			var $this = $(this);

			breakTitleLines($this.find("h2"));
			if(index == $(".page-current .page-header").attr("data-index")) {
				var elem = {};

				elem.bgImage = $this.find(".block-bg-cover img").attr("data-blur");
				elem.coverImage = $this.find(".block-bg-cover img").attr("src");
				elem.element = $this.find("canvas");
				elem.videoUrl = $this.attr('data-video');
				elem.videoCurrentTime = 0;
				elem.menuIndex = index;
				menu_video_array.active = elem;

				$this.find("a").addClass("js-active-page-btn");
			}

			var elem_all = {};

			elem_all.bgImage = $this.find(".block-bg-cover img").attr("data-blur");
			elem_all.coverImage = $this.find(".block-bg-cover img").attr("src");
			elem_all.element = $this.find("canvas");
			elem_all.videoUrl = $this.attr('data-video');
			elem_all.videoCurrentTime = 0;
			elem_all.menuIndex = index;
			menu_video_array.all.push(elem_all);

		});

		//Create the Video holder for the canvas control
		var $video = $("<video>"), 
				$src = $("<source>");

		$video.attr("loop",true);
		$video.attr("poster",menu_video_array.active.coverImage);
		$src.attr("src", menu_video_array.active.videoUrl);
		$video.append($src);

		$video.css({
			'display': 'none',
			'width': _globalViewportW,
			'height': _globalViewportH
		}).addClass("js-menu-video");

		$_body.append($video);

		var playPromise = $video[0].play();
    if (playPromise !== undefined) {
      playPromise.then(function() {
       	
      	playingVideo = $video[0];
       	playingVideo.addEventListener('timeupdate', fadeInVideo);

       	raf_video_id = _rAF_loop( animationLoop );  
      }).catch(function(error) {
        playingVideo = new Image();
	    	playingVideo.src = menu_video_array.active.coverImage;
	    	isImageCover = true;
       	fadeInVideo();
       	raf_video_id = _rAF_loop( animationLoop );  

      });
    } else {
    	playingVideo = $video[0];
    	playingVideo.addEventListener('timeupdate', fadeInVideo);

    	raf_video_id = _rAF_loop( animationLoop ); 

    }
		

		//events
		resizeCanvas();
		$_window.on('resize.headerVideo', $.debounce(500,resizeCanvas));
    	

    $carouselWrapper.find("li.active a.js-active-page-btn").on("click", function(event) {
    	event.stopImmediatePropagation();
	    event.preventDefault();
	    event.stopPropagation();

	    _canvasVideoObj.zoomIn("close");
	    return false;
    })
	}

	var zoomOut = function() {
		if(!can_animate) return false;
		var delay = 0;

		(_scrollY > 0) ? delay = 3000 + 500 : delay = 0;

		var myObject = {value:_scrollY};
	
		if($_html.hasClass("ie") || $_html.hasClass("edge")) {
			TweenMax.to($_window, 1 , {scrollTo: {y: 0}, ease: Expo.easeOut});
		}
		else {
			TweenMax.to(myObject, 3, {value:0, onUpdate:updateScroll, onUpdateParams:["{self}"], ease: Expo.easeOut});

			function updateScroll(tween) {
				_customScroll.setPosition(0, tween.target.value);

			}
		}
		 

		$.doTimeout(delay, function() {
			can_animate = false;

			var ratio = $carouselWrapper.find("li").width()/_globalViewportW,
				 	res = cover(_globalViewportW, _globalViewportH, canvas_dest_width, canvas_dest_height, true),
				 	img = new Image();
	    		img.src = null,
	    		index = $(".page-current .page-header").attr("data-index");; 

	    if($_html.hasClass("edge") || $_html.hasClass("ie"))
	    	img.src = menu_video_array.active.bgImage;
	    else
	    	img.src = canvas_playingVideo.toDataURL();

	    $(img).addClass("img-bg");
	   	$headerVideoWrapper.prepend($(img));

	   	$.doTimeout(100, function() {
	   		
	   		//position canvas and text in the viewport to zoom
	   		$headerCanvasWrapper.css({
					'position' : 'absolute',
					'top': 0,
					'left': 0,
					'z-index': 1000,
					'width': _globalViewportW,
					'height': _globalViewportH,
					'overflow': 'hidden'
				});

				$headerInfoWrapper.css({
					'position' : 'absolute',
					'top': 0,
					'left': 0,
					'z-index': 1000,
					'width': _globalViewportW,
					'height': _globalViewportH,
					'overflow': 'hidden'
				});
				$scrollContent.append($headerCanvasWrapper.detach());
				$scrollContent.append($headerInfoWrapper.detach());

				$scrollContent.append($headerMaskVideoContainer.detach());

				$headerCanvas.wrap("<div class='js-wrapper'></div>");

				$(".js-wrapper").css({
					'position' : 'absolute',
					'top': 0,
					'left': 0,
					'z-index': 1000,
					'width': _globalViewportW + "px",
					'height': _globalViewportH + "px",
					'overflow': 'hidden'
				}); 

				//Setup the menu info
				if(!$_body.hasClass("home"))
					TweenMax.set($carouselWrapper.find("li.active a .button-wrapper"), {autoAlpha: 0, y: 20});

				TweenMax.set([$carouselWrapper.find("li.active .page-image .number-menu"),$carouselWrapper.find("li.active .page-image .page-name")], { autoAlpha: 0, y: 20});

				TweenMax.to($(".scroll-helper"), .5, { autoAlpha: 0});
				
				_menuObj.open();
				_canOpenMenu = true;
				$headerCanvasWrapper.css('perspective', '1000px');

				TweenMax.to($('.page-header .introduction-text'), .3, { opacity: 0 });

				TweenMax.to($headerCanvas.closest(".js-wrapper"), 1, {
					scale: 1 - ratio/2,
					z: 2,
					rotationX: 20,
					transformOrigin: "center center",
					ease: Expo.easeIn,
				});

				TweenMax.to($headerCanvas.closest(".js-wrapper"), 1.5, {
					scale: ratio,
					delay: 1,
					z: 1,
					rotationX: 0,
					transformOrigin: "center center",
					ease: Expo.easeOut,
				});

				TweenMax.to($headerMaskVideoContainer, 1.5, {opacity: .3});

				TweenMax.to($headerInfoWrapper.find(".button-wrapper"), 1.5, {
					scale: 1.25,
					transformOrigin: "center center",
					ease: Expo.easeInOut
				});

				TweenMax.to($headerInfoWrapper, 1.5, {
					scale: .8,
					transformOrigin: "center center",
					delay: .5,
					ease: Expo.easeInOut,
					onComplete: function() { 
						if($_body.hasClass("home")) {
							var btn_offset_top = $carouselWrapper.find("li.active a .button-wrapper")[0].getBoundingClientRect().top - $headerInfoWrapper.find(".button-wrapper")[0].getBoundingClientRect().top,
									btn_offset_left = $carouselWrapper.find("li.active a .button-wrapper")[0].getBoundingClientRect().left - $headerInfoWrapper.find(".button-wrapper")[0].getBoundingClientRect().left;
						}
						else {
							var btn_offset_top = 0,
									btn_offset_left = 0;
						}

						$carouselWrapper.find("li .canvas-menu-video-wrapper").addClass("active");

						//change the canvas context to the menu canvas and start drawing
						canvas_playingVideo = menu_video_array.active.element[0];
						ctx_canvas_playingVideo = menu_video_array.active.element[0].getContext("2d");

						canvas_dest_width = menu_video_array.active.element.width();
						canvas_dest_height = menu_video_array.active.element.height();
						
						resizeCanvas();

						//Reset header elements
						TweenMax.set($headerInfoWrapper.find(".button-wrapper"), {scale: 1, zIndex: 4});
						$headerVideoWrapper.append($headerCanvasWrapper.detach());
						$headerVideoWrapper.append($headerInfoWrapper.detach());

						TweenMax.set($headerMaskVideoContainer, {opacity: 0});
						$headerVideoWrapper.append($headerMaskVideoContainer.detach());

						$headerCanvas.unwrap(".js-wrapper");
						TweenMax.set($headerCanvas, { scale: 1 });
						TweenMax.set($headerCanvasWrapper, {zIndex: '0', display: 'none' });
						TweenMax.set($headerInfoWrapper, {opacity: 0, scale: 1});
						
						if($_body.hasClass("home")) {
							if (!$_html.hasClass("ie") || !$_html.hasClass("edge")) {
								TweenMax.set($carouselWrapper.find("li.active a .button-wrapper"), { autoAlpha: 1, y: - btn_offset_top - 1});
							}
							TweenMax.staggerTo([$carouselWrapper.find("li.active .page-image .number-menu"),$carouselWrapper.find("li.active .page-image .page-name")], 1,{ autoAlpha: 1, y: 0}, .1);
						}
						else
							TweenMax.staggerTo([$carouselWrapper.find("li.active .page-image .number-menu"),$carouselWrapper.find("li.active .page-image .page-name"), $carouselWrapper.find("li.active a .button-wrapper")], 1,{ autoAlpha: 1, y: 0}, .1);

						can_animate = true;
					}
				});
	   	});
		});
	}

	var zoomIn = function(type, f) {
		if(!can_animate) return false;
		can_animate = false;


		if(type == "close") {
			if(menu_video_array.active.menuIndex == $(".page-current .page-header").attr("data-index")) {
				zoomInAnim(type);
			}
			else {
				$("#page-indicator-container li").eq($(".page-current .page-header").attr("data-index")).click();
				$.doTimeout(1200, function() {
					zoomInAnim(type);
				})
			}
		}
		else {

			zoomInAnim(type, f);
		}
		
		function zoomInAnim(type, f) {
			var ratio = Math.min(_globalViewportW / $carouselWrapper.find("li.active .canvas-menu-video-wrapper").width(), _globalViewportH / $carouselWrapper.find("li.active .canvas-menu-video-wrapper").height());

			$(".page-next").css("transform", "translate3d(0,0%,0)");

			if(type == 'close') {
				if($_body.hasClass("home")) {
					var btn_offset_top = $headerInfoWrapper.find(".button-wrapper")[0].getBoundingClientRect().top - $carouselWrapper.find("li.active a .button-wrapper")[0].getBoundingClientRect().top,
							btn_offset_left = $headerInfoWrapper.find(".button-wrapper")[0].getBoundingClientRect().left - $carouselWrapper.find("li.active a .button-wrapper")[0].getBoundingClientRect().left;
					
					TweenMax.to($carouselWrapper.find("li.active a .button-wrapper"), 1.5, {
	  			 	top: btn_offset_top,
	  			 	ease: Expo.easeInOut
	  			});

	  			TweenMax.to($carouselWrapper.find("li.active .frame"), 1.5, { opacity: 1 });

					TweenMax.to([$carouselWrapper.find("li.active .page-image .number-menu"),$carouselWrapper.find("li.active .page-image .page-name")], .5,{ autoAlpha: 0, y: 20});
				}
				else {
					var btn_offset_top = 0,
							btn_offset_left = 0;

					TweenMax.to($carouselWrapper.find("li.active a .button-wrapper"), 1, {autoAlpha: 0, y: 20});
					TweenMax.to([$carouselWrapper.find("li.active .page-image .number-menu"),$carouselWrapper.find("li.active .page-image .page-name"), $carouselWrapper.find("li.active a .button-wrapper")], .5,{ autoAlpha: 0, y: 20});
				}
			}
			else {
				if($(".page-next .page-toload").hasClass("home-page")) {
					TweenMax.set($(".page-next .page-toload .page-header .header-info-wrapper .button-wrapper"),{className: "+=showBtn"});
					TweenMax.set($(".page-next .page-toload .page-header .header-info-wrapper .button-wrapper .button-inner"), {height: 60});
					TweenMax.set($(".page-next .page-toload .page-header .header-info-wrapper .button-wrapper").find(".discover-line-btn"), {opacity: 1});

					var btn_offset_top = $(".page-next .page-toload .page-header .header-info-wrapper .button-wrapper")[0].getBoundingClientRect().top - $carouselWrapper.find("li.active a .button-wrapper")[0].getBoundingClientRect().top,
							btn_offset_left = $(".page-next .page-toload .page-header .header-info-wrapper .button-wrapper")[0].getBoundingClientRect().left - $carouselWrapper.find("li.active a .button-wrapper")[0].getBoundingClientRect().left;
					
					TweenMax.to($carouselWrapper.find("li.active a .button-wrapper"), 1.5, {
	  			 	top: btn_offset_top,
	  			 	ease: Expo.easeInOut
	  			});
				}
				else {
					TweenMax.to($carouselWrapper.find("li.active a .button-wrapper"), 1, {autoAlpha: 0, y: 20});
					TweenMax.to([$carouselWrapper.find("li.active .page-image .number-menu"),$carouselWrapper.find("li.active .page-image .page-name"), $carouselWrapper.find("li.active a .button-wrapper")], .5,{ autoAlpha: 0, y: 20});
				}
			}
			
			TweenMax.to("#page-indicator-container", 1, {opacity: 0 });
			//TweenMax.to("#menu-container .carousel-wrapper li.active .frame", 1, { opacity: 0 });

			$carouselWrapper.find("li .canvas-menu-video-wrapper").removeClass("active");
			$carouselWrapper.find("li.active .page-image").css('perspective', '1000px'); 
			TweenMax.to($carouselWrapper.find("li.active .canvas-menu-video-wrapper"), 1, {
				scale: 1 + ratio/4,
				z: 2,
				rotationX: -20,
				transformOrigin: "center center",
				ease: Expo.easeIn,
			});

			TweenMax.to($carouselWrapper.find("li.active .canvas-menu-video-wrapper"), 1.5, {
				scale: ratio,
				delay: 1,
				z: 1,
				rotationX: 0,
				transformOrigin: "center center",
				ease: Expo.easeOut,
			});
			
			TweenMax.to($carouselWrapper.find("li.active .page-image .page-title"), 1.5, {
				scale: 1,
				transformOrigin: "center center",
				ease: Expo.easeOut,
				onComplete: function() {

					if(type == "close") {
						_menuObj.close();
						_canOpenMenu = false; 

						//change the canvas context to the menu canvas and start drawing
						canvas_playingVideo = $headerCanvas[0];
						ctx_canvas_playingVideo = canvas_playingVideo.getContext("2d");
						canvas_dest_width = _globalViewportW;
		  			canvas_dest_height = _globalViewportH;
		  			resizeCanvas();

		  			//Show page header canvas and other elements
		  			TweenMax.set($headerInfoWrapper, { 
							clearProps: 'all'
						});
		  			TweenMax.set($headerCanvasWrapper, { display: 'block' });
		  			TweenMax.to($('.page-header .introduction-text'), .3, { opacity: 1 });
						TweenMax.to($headerMaskVideoContainer, .5, { autoAlpha: 1 });
						TweenMax.to($(".scroll-helper"), .5, { autoAlpha: 1});

						//reset menu elements
						TweenMax.set($carouselWrapper.find("li.active a .button-wrapper"), {top: 0});
						$carouselWrapper.find("li.active .page-image").attr('style', '');
						TweenMax.set($carouselWrapper.find("li.active .canvas-menu-video-wrapper"), { scale: 1 });
						TweenMax.set($carouselWrapper.find("li.active .page-title"), {scale: .8});

						$headerVideoWrapper.find(".img-bg").remove();
						

						if($_body.hasClass("home"))
							$carouselWrapper.find("li.active a").css("opacity", 1);

						changeMenuIndex($(".page-current .page-header").attr("data-index"));

						can_animate = true;
					}
					else {

						manageBodyClasses();

          	$_hamburguer.toggleClass("active");

						TweenMax.set($(".page-next .page-header .video-canvas-wrapper"), {autoAlpha: 1});

						_menuObj.close();
      			_canOpenMenu = false;
 						
 						TweenMax.set($(".page-next .page-header .header-info-wrapper .frame-title"), {opacity: 1});
 						TweenMax.to($(".scroll-helper"), .5, { autoAlpha: 1});

          	$headerVideoWrapper = $(".page-next .page-header .video-wrapper");
						$headerInfoWrapper = $(".page-next .page-header .header-info-wrapper");
						$headerMaskVideoContainer = $(".page-next .page-header .frame");
						$headerCanvasWrapper = $(".page-next .page-header .video-canvas-wrapper");
						$headerCanvas = $headerCanvasWrapper.find("canvas");

						TweenMax.set($headerMaskVideoContainer, { rotation: 90, scale: 1 });
						TweenMax.to($headerMaskVideoContainer, 1, { autoAlpha: 1 });

						canvas_playingVideo = $(".page-next .page-header canvas")[0];
						ctx_canvas_playingVideo = canvas_playingVideo.getContext("2d");
				    canvas_dest_width = _globalViewportW;
				    canvas_dest_height = _globalViewportH;

				    $(".page-current").css({
          		'visibility': 'hidden',
          	});
          	$(".page-next").css({
          		'transform': 'translate3d(0, 0%, 0)',
          		'visibility': 'visible',
          		'z-index': '10'
          	});

						resizeCanvas();

						TweenMax.set(".audio-wrapper h2", {y: 0});
						TweenMax.set(".audio-state", {y: 0});
						TweenMax.set('#cipro-logo', {scale: 1, opacity: 1});
						TweenMax.set(".frame-menu", {opacity: 1})
						TweenMax.set("#lines", {scaleX: 1, onComplete: function(){
							_canClick = true;
						}});

          	clearPagesAfterloading();

          	$(".discover-line-btn").each(function(){
		          var $this = $(this);
		          breakTitleLetters($this);
		        });

						//reset menu elements
						TweenMax.set($carouselWrapper.find("li.active a .button-wrapper"), {top: 0});
						$carouselWrapper.find("li.active .page-image").attr('style', '');
						TweenMax.set($carouselWrapper.find("li.active .canvas-menu-video-wrapper"), { scale: 1 });
						TweenMax.set($carouselWrapper.find("li.active .page-title"), {scale: .8});

						breakTitleLetters($headerInfoWrapper.find(".discover-line-btn"));
						if($_body.hasClass("home")) {
							
							
						}
						else {
							TweenMax.to(".button-wrapper", 1, {className: "+=showBtn", ease: Expo.easeOut, delay: 1});
							TweenMax.to(".button-wrapper .button-inner", 1, {height: $(".button-wrapper").outerHeight(), ease: Expo.easeOut, delay: .5});
							TweenMax.to(".discover-line-btn", 1, {opacity: 1, ease: Quad.easeOut, delay: .3});
						}
						
					
						canvas_playingVideo = $(".page-next .page-header canvas")[0];
						ctx_canvas_playingVideo = canvas_playingVideo.getContext("2d");

						$.doTimeout(500, function() {
							$(".js-active-page-btn").removeClass("js-active-page-btn");

							$carouselWrapper.find("li").each(function(index) {
								var $this = $(this); 

								if(index == $(".page-current .page-header").attr("data-index")) {
									$this.find("a").addClass("js-active-page-btn");
								}
							});
							changeMenuIndex($(".page-header").attr("data-index"));

							can_animate = true;
	  	
						})
						
					}		
				}
			});
		}

	}

	var pauseOnMenu = function() { 
		$(".js-menu-video")[0].pause();

		menu_video_array.active.videoCurrentTime = $(".js-menu-video")[0].currentTime;

		if($_html.hasClass("edge") || $_html.hasClass("ie"))
			$carouselWrapper.find("li.active .page-image .element-cover").attr("src", menu_video_array.active.bgImage);
    else
    	$carouselWrapper.find("li.active .page-image .element-cover").attr("src", canvas_playingVideo.toDataURL());
	}

	var playOnMenu = function(index) {
		if(index != undefined && index != menu_video_array.active.menuIndex)
			activateNextMenu(index)	
	}

	var activateNextMenu = function(index) {
		var elem_new_active = menu_video_array.all[index],
				elem_old_active = menu_video_array.active,
				$video = $("<video>"),
				$src = $("<source>"),
				$imgWrapper = $("<div></div>"),
				$bgImage = $("<img>"),
				$oldBgImg = $(".js-bg-wrapper");

		$video.attr("loop",true);
		$src.attr("src", $carouselWrapper.find("li").eq(index).attr("data-video"));
		$video.append($src);

		menu_video_array.active = elem_new_active;
		menu_video_array.all[elem_old_active.menuIndex] = elem_old_active;

		$bgImage.attr("src", menu_video_array.active.bgImage).css({
			'position': 'absolute',
			'width': '100%',
			'max-width': "100%",
			'height': '100%',
			'top': '0',
			'left': '0',
		}).addClass("js-bg-image");

		$imgWrapper.css({
			'position': 'absolute',
			'width': '110%',
			'max-width': "110%",
			'height': '110%',
			'top': '-5%',
			'left': '-5%',
			'z-index': '-1',
		}).addClass("js-bg-wrapper");

		TweenMax.set($bgImage, {opacity : 0 });
		$imgWrapper.append($bgImage);
		$("#menu-container").append($imgWrapper);

		TweenMax.to($bgImage, .1, {opacity : 1,  onComplete: function() {$oldBgImg.remove();} });

		if($_html.hasClass("ie") || !$_html.hasClass("edge"))
			$video[0].addEventListener('loadedmetadata', function() {
			  $video[0].currentTime = menu_video_array.active.videoCurrentTime;
			}, false);
		else
			$video[0].currentTime = menu_video_array.active.videoCurrentTime;

		$(".js-menu-video").remove();
		$video.css('display','none').addClass("js-menu-video");
		$_body.append($video);

		var playPromise = $video[0].play();
    if (playPromise !== undefined) {
      playPromise.then(function() {
        // alert("entrou")
      }).catch(function(error) {
        // alert("NAO entrou")
      });
    }
		

		canvas_playingVideo = menu_video_array.active.element[0];
		ctx_canvas_playingVideo = menu_video_array.active.element[0].getContext("2d");
		canvas_dest_width = menu_video_array.active.element.width();
		canvas_dest_height = menu_video_array.active.element.height();
		
		resizeCanvas();

		playingVideo = $video[0];
	}

	var newPageVars = function($nextContent) {

		$headerVideoWrapper = $(".page-header .video-wrapper");
		$headerInfoWrapper = $(".page-header .header-info-wrapper");
		$headerCanvasWrapper = $(".page-header .video-canvas-wrapper");
		$headerCanvas = $headerCanvasWrapper.find("canvas");
		canvas_playingVideo = $headerCanvas[0];

		canvas_playingVideo = $nextContent.find(".page-header canvas")[0];
		ctx_canvas_playingVideo = canvas_playingVideo.getContext("2d");
    canvas_dest_width = _globalViewportW;
    canvas_dest_height = _globalViewportH;
		
		resizeCanvas();
	}

	function resizeCanvas() {

		// if($_html.hasClass("mobile")) {
		// 	console.log(canvas_dest_width, canvas_dest_height)
		// 	res = cover(1920, 1080, canvas_dest_width, canvas_dest_height, false);
		// }
		// else {
		// 	//res = cover(_globalViewportW, _globalViewportH, canvas_dest_width, canvas_dest_height, true);

		// 	console.log(canvas_dest_width, canvas_dest_height)
		// 	res = cover(1920, 1080, canvas_dest_width, canvas_dest_height, false);
		// }

		//res = cover(_globalViewportW, _globalViewportH, canvas_dest_width, canvas_dest_height, true);

		if($("#hamburguer-container").hasClass("active")) { 
			var w_dest = canvas_dest_width,
					h_dest = canvas_dest_height;
		}
		else {
			var w_dest = $_window.width(),
					h_dest = $_window.height();
		}
		res = cover(1920, 1080, w_dest, h_dest, false);

		cw = res.width;
    ch = res.height;
		canvas_playingVideo.width = cw;
    canvas_playingVideo.height = ch;
    // canvas_playingVideo.left = res.left;
    // canvas_playingVideo.top = res.top;

    $(canvas_playingVideo).css({
    	'width': res.width,
    	'height': res.height,
    	'left': res.left,
    	'top': res.top
    });
	}

	function animationLoop() {
    raf_video_id = _rAF_loop( animationLoop );
    ctx_canvas_playingVideo.drawImage(playingVideo, 0, 0, cw, ch);
  } 

  function fadeInVideo() {
  	$(document).trigger("videoLoaded");
  	TweenMax.to($headerCanvasWrapper, .5, {
  		autoAlpha: 1
  	});
  	if(!isImageCover)
  		playingVideo.removeEventListener('timeupdate', fadeInVideo);
  }

	var kill = function() {
		window.cancelAnimationFrame(raf_video_id);
		$_window.off("resize.headerVideo");
	}

	return {
		init: init,
		kill: kill,
		zoomOut: zoomOut,
		zoomIn: zoomIn,
		pauseOnMenu: pauseOnMenu,
		playOnMenu: playOnMenu,
		newPageVars: newPageVars,
		activateNextMenu: activateNextMenu
	}
}

function cover(srcW, srcH, targetW, targetH, contain) {
  var output = {};

  if (contain) {
    // https://opensourcehacker.com/2011/12/01/calculate-aspect-ratio-conserving-resize-for-images-in-javascript/
    var ratio = Math.min(targetW / srcW, targetH / srcH);
    output.width = srcW * ratio;
    output.height = srcH * ratio;
  } else {
    output.width = targetW;
    output.height = (srcH * targetW) / srcW;

    if (output.height < targetH) {
      output.width = (srcW * targetH) / srcH;
      output.height = targetH;
    }
  }

  output.left = Math.round((targetW - output.width) / 2);
  output.top = Math.round((targetH - output.height) / 2);

  return output;
}


/*
 * Easing Functions - inspired from http://gizma.com/easing/
 * only considering the t value for the range [0, 1] => [0, 1]
 https://gist.github.com/gre/1650294
 */


function linear(t) { return t };
function easeInQuad(t) { return t*t };
function easeOutQuad(t) { return t*(2-t) };
function easeInOutQuad(t) { return t<.5 ? 2*t*t : -1+(4-2*t)*t };
function easeInCubic(t) { return t*t*t };
function easeOutCubic(t) { return (--t)*t*t+1 };
function easeInOutCubic(t) { return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1 };
function easeInQuart(t) { return t*t*t*t };
function easeOutQuart(t) { return 1-(--t)*t*t*t };
function easeInOutQuart(t) { return t<.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t };
function easeInQuint(t) { return t*t*t*t*t };
function easeOutQuint(t) { return 1+(--t)*t*t*t*t };
function easeInOutQuint(t) { return t<.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t }
function exponentialOut(t) {return t == 1.0 ? t : 1.0 - Math.pow(2.0, -10.0 * t);}


norm = function (value, min, max) {
  return (value - min) / (max - min)
}

lerp = function (norm, min, max) {
  return (max - min) * norm + min
}

map = function (value, sourceMin, sourceMax, destMin, destMax) {
  return lerp(norm(value, sourceMin, sourceMax), destMin, destMax)
}

lerpEase = function(start, end, amt){
  return (1-amt)*start+amt*end
}