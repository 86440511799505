function conceptPage(do_concept) {
 
  /*variables*/
  var reachFooterObj = reachFooter();

  /*DOM variables*/
  var $list = $(".list");

  var init = function() {
    if(_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      _scrollRef = function() { _raf_loop_id = _rAF_loop( concept_scroll_rAF ); }
      $_window[0].addEventListener("scroll", _scrollRef, { passive: true } );
    }
    else {
      _page_scroll_listener = function(status) {
        concept_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }

    /*inits*/
    if ($_body.hasClass("phone")) {
      var playPromise = $("#mobile-video")[0].play();
      if (playPromise !== undefined) {
            playPromise.then(function() {
              // alert("entrou")
            }).catch(function(error) {
              // alert("NAO entrou")
            });
      }
    }

    _creditsObj = credits();
    if (_creditsObj) {
      _creditsObj.init();
    }
    TweenMax.set($(".big-photo-container").find(".vertical-image .block-bg-cover"), {clip:"rect(0px "+$(".big-photo-container").find(".vertical-image .block-bg-cover").outerWidth()+"px 0px 0px)"});
    
    if($_body.hasClass("js-no-ajax")) {
      _audioObj = initAudios({
        defaultVolume: .5,
        fadeTime: 1000,
        audioFile: '/public/video/audio/concept_sprite.mp3',
        sprite:  {
          header: [0, 57648.0045351474, true],
          scroll: [59000, 57648.0045351474, true]
        }
      });
      
      if(_audioObj){
        _audioObj.init();
      }
    }

    
    
    /*events*/
    addEvents();
  }

  var kill = function() {
    if(_customScroll)
      _customScroll.removeListener(_page_scroll_listener);
  }

  return {
    init: init,
    kill: kill
  }

  /*page functions*/

  function addEvents() {
    if ($_body.hasClass("phone")) {
      featuringList($list);
    }
  }

  function concept_scroll_rAF(status) {
    if(_customScroll)
      _scrollLimit = _customScroll.limit.y;
    
    if (!$_body.hasClass("mobile")) {
      if(_scrollY > _globalViewportH)
        $(".js-menu-video")[0].pause();
      else
        $(".js-menu-video")[0].play();
    }
    
    reachFooterObj.animate();
    _scrollAnimations.animate();

    if (!$_body.hasClass("mobile")) {
      if (!$_html.hasClass("ie") && !$_html.hasClass("edge")) {
        if(status.offset.y > _globalViewportH && status.direction.y == "down" && !$_body.hasClass("js-faded-audio-scroll")) {
          $_body.addClass("js-faded-audio-scroll");
          _audioObj.fadeOut("scroll"); 
        }
        else if(status.offset.y < _globalViewportH && status.direction.y == "up" && $_body.hasClass("js-faded-audio-scroll")) {
          $_body.removeClass("js-faded-audio-scroll");
          _audioObj.fadeIn("scroll");  
        }
      } else {
        if(_scrollY > _globalViewportH && _scrollDirection == "down" && !$_body.hasClass("js-faded-audio-scroll")) {
          $_body.addClass("js-faded-audio-scroll");
          _audioObj.fadeOut("scroll"); 
        }
        else if(_scrollY < _globalViewportH && _scrollDirection == "up" && $_body.hasClass("js-faded-audio-scroll")) {
          $_body.removeClass("js-faded-audio-scroll");
          _audioObj.fadeIn("scroll");  
        }
      }
    }
  }

} //////end function conceptPage