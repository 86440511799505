function workPage(do_work) {

  /*variables*/
  var anim,
      animateSentences = animateSentence(),
      arr = [],
      reachFooterObj = reachFooter();

  /*DOM variables*/
  var $mouseFollow = $('#mouse-follow'),
      $centerImage = $(".center-image"),
      $workContainer = $(".work-container"),
      $workContainerFirst = $(".work-container").eq(0),
      $sentenceCenter = $(".sentence-center"),
      $sentenceContainer = $(".sentence-container"),
      $sentenceLeft = $(".sentence-left"),
      $sentenceRight = $(".sentence-right"),
      $sentenceLateral = $(".sentence-lateral"),
      $sentenceCarousel = $(".sentence-carousel"),
      $sentenceCarouselInside = $(".sentence-carousel-inside"),
      $arrow = $(".arrow"),
      $closeLightBox = $(".close-lightbox"),
      $slidesContainer = $(".slides-container"),
      $zoomContainer = $(".zoom-container"),
      $mouseZoom = $(".mouse-zoom"),
      $mouseLoading = $(".mouse-loading"),
      $list = $(".list"),
      lightBox = lightboxCarousel(),
      $lightboxContainer = $(".lightbox-container");

  var init = function() {
    if(_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      _scrollRef = function() { _raf_loop_id = _rAF_loop( work_scroll_rAF ); }
      $_window[0].addEventListener("scroll", _scrollRef, { passive: true } );
    }
    else {
      _page_scroll_listener = function(status) {
        work_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }

    /*inits*/
    if ($_body.hasClass("phone")) {
      var playPromise = $("#mobile-video")[0].play();
      if (playPromise !== undefined) {
            playPromise.then(function() {
              // alert("entrou")
            }).catch(function(error) {
              // alert("NAO entrou")
            });
      }
    }
    if ($_body.hasClass("phone")) {
      featuringList($list);
    }
    TweenMax.set($mouseFollow, {y: $workContainerFirst.offset().top + parseInt($workContainerFirst.find(".center-image").css("marginTop")) + ($workContainerFirst.find(".center-image").height()) - $mouseFollow.height()*1.5, x: _globalViewportW/2})

    if($_body.hasClass("js-no-ajax")) {
      _audioObj = initAudios({
        defaultVolume: .5,
        fadeTime: 1000,
        audioFile: '/public/video/audio/work_sprite.mp3',
        sprite:  {
          header: [0, 76852.24489795919, true],
          scroll: [78000, 57652.24489795918, true]
        }
      });
      if(_audioObj)
        _audioObj.init();
    }
    
    _creditsObj = credits();
    if (_creditsObj) {
      _creditsObj.init();
    }

    addEvents();
  }

  var kill = function() {
    if(_customScroll)
      _customScroll.removeListener(_page_scroll_listener);
  }

  return {
    init: init,
    kill: kill
  }
  
  /*page functions*/

  function addEvents() {
    if (!$_body.hasClass("mobile")) {
      $workContainer.on('mousemove', moveBox);
      function moveLoading(e){
        TweenMax.to( $mouseLoading, 0.1, {
          x: e.clientX,
          y: e.clientY + _scrollY,
          force3D: true,
          ease:Linear.easeNone
        });
      }
    }
    
    $workContainer.each(function(){
      var $this = $(this),
          distance = $this.offset().top + parseInt($this.find(".center-image").css("marginTop")) + ($this.find(".center-image").height() - $mouseFollow.height()*1.5),
          sentenceWidth = Math.floor($this.find(".sentence-carousel h2").width()),
          whiteSpace = Math.floor((_globalViewportW - sentenceWidth) / 2),
          finalWidth = sentenceWidth + whiteSpace;

      $this.css("height", $this.find(".row").height());
      $this.find(".center-image").parent().css("height", $this.find(".row").height());
      arr.push(distance);
      if ($_body.hasClass("phone")) {
        TweenMax.set($this.find(".sentence-carousel-inside, .sentence-carousel"), {x: _globalViewportW, autoAlpha: 1});
      } else {
        TweenMax.set($this.find(".sentence-carousel-inside, .sentence-carousel"), {x: finalWidth, autoAlpha: 1});
      }
    });

    if (!$_body.hasClass("mobile")) {
      $workContainer.eq(2).find(".center-image").css("height", $workContainer.eq(2).find(".row").height());
      $workContainer.eq(3).find(".center-image").css("height", $workContainer.eq(3).find(".row").height());
    }

    animateSentences.init();

    $workContainer.on('click', function() {
      var $this = $(this),
          $targetImage = $this.find('.center-image .element-cover'),
          index = $this.index();

      if (!$_body.hasClass("mobile")) {
        TweenMax.set( $mouseLoading, {
          x: _mouseX,
          y: _mouseY + _scrollY
        });
      } else {
        TweenMax.to($this.find(".click-plus"), 1, {scale: 0, ease: Quad.easeOut});
        TweenMax.set( $mouseLoading, {
          y: arr[index-3]-40,
          x: _globalViewportW/2 - 40
        });
      }
      
      $workContainer.on('mousemove.Loading', moveLoading);
      $_body.addClass("js-lightbox-open");
      TweenMax.to($mouseFollow, 0.6, {scale: 0, ease: Quad.easeOut});
      TweenMax.set($mouseLoading, {autoAlpha: 1});
      TweenMax.to($mouseLoading.find("span:first-of-type"), 0.6, {scale: 0.3, repeat: -1, yoyo: true, ease: Quad.easeOut});
      TweenMax.to($mouseLoading.find("span:last-of-type"), 0.6, {scale: 0.7, repeat: -1, yoyo: true, delay: 0.2, ease: Quad.easeOut});
      $.doTimeout(3000, function(){
        lightBox.init($targetImage);
        $workContainer.off('mousemove.Loading');
      });
    });

    $arrow.on("click", function(){
      var $this = $(this),
          direction = $this.attr("data-direction");

      lightBox.clickArrow(direction);
      if ($_body.hasClass("mobile") && $_html.hasClass("safari")) {
        TweenMax.to(".lightbox-container .arrow, .lightbox-container .total-slides-container", 1, {y: -70, ease: Expo.easeOut});
      }
    });

    $closeLightBox.on("click", function(){
      lightBox.close();
    });

    $slidesContainer.on("click", function(){
      var $this = $(this);
      if(!$_body.hasClass("mobile") && !$_body.hasClass("isAnimating") && !$_body.hasClass("isZoom")) {
        lightBox.zoom($this);
      }
    });

    $zoomContainer.on("click", function(){
      if(!$_body.hasClass("mobile")) {
        lightBox.closeZoom();
      }
    });

    $workContainer.mouseleave(function() {
      var $this = $(this),
          index = $this.index()-2;
      TweenMax.to($mouseFollow, 0.8, {x: _globalViewportW/2, y:arr[index]-10, force3D: true, ease: Power2.easeInOut});
    });

    $arrow.hover(function(){
      TweenMax.to($mouseZoom, 0.4, {scale: 0, ease: Quad.easeOut});
    }, function(){
      TweenMax.to($mouseZoom, 0.4, {scale: 1, ease: Quad.easeOut});
    });

    $closeLightBox.hover(function(){
      TweenMax.to($mouseZoom, 0.4, {scale: 0, ease: Quad.easeOut});
    }, function(){
      TweenMax.to($mouseZoom, 0.4, {scale: 1, ease: Quad.easeOut});
    });

    $lightboxContainer.on("click", function(){
      if ($_body.hasClass("mobile") && $_html.hasClass("safari")) {
        TweenMax.to(".lightbox-container .arrow, .lightbox-container .total-slides-container", 1, {y: -70, ease: Expo.easeOut});
      }
    });

  }

  function moveBox (e) { 
    TweenMax.to( $mouseFollow, 0.1, {
      x: e.clientX,
      y: e.clientY + _scrollY,
      force3D: true,
      ease:Linear.easeNone
    });
  }

  function animateSentence(){
    var init = function(){

      if (!$_body.hasClass("mobile")) {
        $_window.on('resize.Sentence', $.debounce(500, resizeSentence));
      }

      $sentenceCenter.each(function(){
        var $this = $(this);
        $this.css("left", "-" + $this.closest(".center-image").offset().left + "px");
      });

      $sentenceContainer.each(function(){
        var $this = $(this);
        $this.css("top", $this.parent(".work-container").find(".sentence-center").offset().top - $this.parent(".work-container").offset().top + "px");
      });

      $sentenceLeft.each(function(){
        var $this = $(this);
        $this.css("left", "-" + $this.parent(".left-image").offset().left + "px");
      });

      $sentenceRight.each(function(){
        var $this = $(this);
        $this.css("left", "-" + $this.parent(".right-image").offset().left + "px");
      });

      $sentenceLateral.each(function(){
        var $this = $(this);
        $this.css("top", $this.closest(".work-container").find(".sentence-center").offset().top - $this.closest(".work-container").offset().top - parseInt($this.parent(".image-container").css("marginTop")) + "px");
      });
    }

    function resizeSentence(){
        $sentenceCenter.each(function(){
          var $this = $(this);
          $this.css("left", "-" + $this.closest(".center-image").offset().left + "px");
        });

        $sentenceContainer.each(function(){
          var $this = $(this);
          $this.css("top", $this.parent(".work-container").find(".sentence-center").offset().top - $this.parent(".work-container").offset().top + "px");
        });

        $sentenceLeft.each(function(){
          var $this = $(this);
          $this.css("left", "-" + $this.parent(".left-image").offset().left + "px");
        });

        $sentenceRight.each(function(){
          var $this = $(this);
          $this.css("left", "-" + $this.parent(".right-image").offset().left + "px");
        });

        $sentenceLateral.each(function(){
          var $this = $(this);
          $this.css("top", $this.closest(".work-container").find(".sentence-center").offset().top - $this.closest(".work-container").offset().top - parseInt($this.parent(".image-container").css("marginTop")) + "px");
        });

        $(".work-container").each(function(){
          var $this = $(this),
              sentenceWidth = Math.floor($this.closest($workContainer).find(".sentence-carousel h2").width()),
              whiteSpace = Math.floor((_globalViewportW - sentenceWidth) / 2),
              finalWidth = sentenceWidth + whiteSpace;

          TweenMax.to($this.find(".sentence-carousel-inside, .sentence-carousel"), 15, {x: -finalWidth, repeat: -1, ease: Linear.easeNone});
        });

    }

    return {
      init: init
    }
  }

  function work_scroll_rAF(status) {
    if(_customScroll)
      _scrollLimit = _customScroll.limit.y;

    if (!$_body.hasClass("mobile")) {
      if(_scrollY > _globalViewportH)
        $(".js-menu-video")[0].pause();
      else
        $(".js-menu-video")[0].play();
    }

    reachFooterObj.animate();
    _scrollAnimations.animate();

    if (!$_body.hasClass("mobile")) {
      if (!$_html.hasClass("ie") && !$_html.hasClass("edge")) {
        if(status.offset.y > _globalViewportH && status.direction.y == "down" && !$_body.hasClass("js-faded-audio-scroll")) {
          $_body.addClass("js-faded-audio-scroll");
          _audioObj.fadeOut("scroll"); 
        }
        else if(status.offset.y < _globalViewportH && status.direction.y == "up" && $_body.hasClass("js-faded-audio-scroll")) {
          $_body.removeClass("js-faded-audio-scroll");
          _audioObj.fadeIn("scroll");  
        }
      } else {
        if(_scrollY > _globalViewportH && _scrollDirection == "down" && !$_body.hasClass("js-faded-audio-scroll")) {
          $_body.addClass("js-faded-audio-scroll");
          _audioObj.fadeOut("scroll"); 
        }
        else if(_scrollY < _globalViewportH && _scrollDirection == "up" && $_body.hasClass("js-faded-audio-scroll")) {
          $_body.removeClass("js-faded-audio-scroll");
          _audioObj.fadeIn("scroll");  
        }
      }
    }
  }
} //////end function workPage