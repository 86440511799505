function aboutPage(do_about) {

  /*variables*/
  var canAnimateButton = true,
      lightBox = lightboxCarousel(),
      reachFooterObj = reachFooter(),
      arr = [];


  /*DOM variables*/
  var $discoverBtn = $(".discover-block-btn"),
      $arrow = $(".arrow"),
      $closeLightBox = $(".close-lightbox"),
      $slidesContainer = $(".slides-container"),
      $zoomContainer = $(".zoom-container"),
      $mouseZoom = $(".mouse-zoom"),
      $mouseLoading = $(".mouse-loading"),
      $ciproSlideshowContainer = $(".cipro-complex-slideshow"),
      $list = $(".list"),
      $lightboxContainer = $(".lightbox-container");

  var init = function() {
    if(_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      _scrollRef = function() { _raf_loop_id = _rAF_loop( about_scroll_rAF ); }
      $_window[0].addEventListener("scroll", _scrollRef, { passive: true } );
    }
    else {
      _page_scroll_listener = function(status) {
        about_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }

    /*inits*/
    if ($_body.hasClass("phone")) {
      var playPromise = $("#mobile-video")[0].play();
      var playPromise2 = $("#mobile-video-2")[0].play();
      if (playPromise !== undefined) {
            playPromise.then(function() {
              // alert("entrou")
            }).catch(function(error) {
              // alert("NAO entrou")
            });
      }
      if (playPromise2 !== undefined) {
            playPromise2.then(function() {
              // alert("entrou")
            }).catch(function(error) {
              // alert("NAO entrou")
            });
      }
    }

    if($_body.hasClass("js-no-ajax")) {
      _audioObj = initAudios({
        defaultVolume: .5,
        fadeTime: 1000,
        audioFile: '/public/video/audio/about_sprite.mp3',
        sprite:  {
          header: [0, 96052.24489795919, true],
          scroll: [98000, 76852.24489795917, true]
        }
      });
      
      if(_audioObj)
        _audioObj.init();
    }
    

    _creditsObj = credits();
    if (_creditsObj) {
      _creditsObj.init();
    }

    addEvents();
  }

  var kill = function() {
    if(_customScroll)
      _customScroll.removeListener(_page_scroll_listener);
  }

  return {
    init: init,
    kill: kill
  }
  
  /*page functions*/

  function addEvents() {
    
    /*events*/
    if (!$_body.hasClass("mobile")) {
      function moveLoading(e){
        TweenMax.to( $mouseLoading, 0.1, {
          x: e.clientX,
          y: e.clientY + _scrollY,
          force3D: true,
          ease:Linear.easeNone
        });
      }
    }
    if ($_body.hasClass("phone")) {
      featuringList($list);
    }
 
    $discoverBtn.on('click', function() {
      var $this = $(this),
          $targetImage = $this.closest(".cipro-complex-slideshow").find('.element-cover'),
          index = $this.closest($ciproSlideshowContainer).index()-1,
          topDistance = 0;

      if ($this.closest(".cipro-complex-slideshow").hasClass("exterior")) {
        topDistance = arr[0];
      } else {
        topDistance = arr[1];
      }

      if (!$_body.hasClass("mobile")) {
        TweenMax.set( $mouseLoading, {
          x: _mouseX,
          y: _mouseY + _scrollY
        });
      } else {
        TweenMax.to($this.find("svg"), 0.4, {scale: 0, ease: Quad.easeOut});
        TweenMax.set($mouseLoading, {
          y: topDistance-35,
          x: _globalViewportW/2+20
        });
      }

      $ciproSlideshowContainer.on('mousemove.Loading', moveLoading);
      $_body.addClass("js-lightbox-open");
      TweenMax.set($mouseLoading, {autoAlpha: 1});
      TweenMax.to($mouseLoading.find("span:first-of-type"), 0.6, {scale: 0.3, repeat: -1, yoyo: true, ease: Quad.easeOut});
      TweenMax.to($mouseLoading.find("span:last-of-type"), 0.6, {scale: 0.7, repeat: -1, yoyo: true, delay: 0.2, ease: Quad.easeOut});
      $.doTimeout(3000, function(){
        lightBox.init($targetImage);
        $ciproSlideshowContainer.off('mousemove.Loading');
      });
    });

    $ciproSlideshowContainer.each(function(){
      var $this = $(this),
          distance = $this.find($discoverBtn).find("svg").offset().top;

      arr.push(distance);
    });

    $arrow.on("click", function(){
      var $this = $(this),
          direction = $this.attr("data-direction");

      lightBox.clickArrow(direction);
      if ($_body.hasClass("mobile") && $_html.hasClass("safari")) {
        TweenMax.to(".lightbox-container .arrow, .lightbox-container .total-slides-container", 1, {y: -70, ease: Expo.easeOut});
      }
    });

    $closeLightBox.on("click", function(){
      lightBox.close();
    });

    $slidesContainer.on("click", function(){
      var $this = $(this);
      if(!$_body.hasClass("mobile") && !$_body.hasClass("isAnimating") && !$_body.hasClass("isZoom")) {
        lightBox.zoom($this);
      }
    });

    $zoomContainer.on("click", function(){
      if(!$_body.hasClass("mobile")) {
        lightBox.closeZoom();
      }
    });

    $arrow.hover(function(){
      TweenMax.to($mouseZoom, 0.4, {scale: 0, ease: Quad.easeOut});
    }, function(){
      TweenMax.to($mouseZoom, 0.4, {scale: 1, ease: Quad.easeOut});
    });

    $closeLightBox.hover(function(){
      TweenMax.to($mouseZoom, 0.4, {scale: 0, ease: Quad.easeOut});
    }, function(){
      TweenMax.to($mouseZoom, 0.4, {scale: 1, ease: Quad.easeOut});
    });

    $lightboxContainer.on("click", function(){
      if ($_body.hasClass("mobile") && $_html.hasClass("safari")) {
        TweenMax.to(".lightbox-container .arrow, .lightbox-container .total-slides-container", 1, {y: -70, ease: Expo.easeOut});
      }
    });
  }

  function about_scroll_rAF(status) {
    if(_customScroll)
      _scrollLimit = _customScroll.limit.y;

    if (!$_body.hasClass("mobile")) {
      if(_scrollY > _globalViewportH)
        $(".js-menu-video")[0].pause();
      else
        $(".js-menu-video")[0].play();
    }
    reachFooterObj.animate();
    _scrollAnimations.animate();
    if (!$_body.hasClass("mobile")) {
      if (!$_html.hasClass("ie") && !$_html.hasClass("edge")) {
        if(status.offset.y > _globalViewportH && status.direction.y == "down" && !$_body.hasClass("js-faded-audio-scroll")) {
          $_body.addClass("js-faded-audio-scroll");
          _audioObj.fadeOut("scroll");
        }
        else if(status.offset.y < _globalViewportH && status.direction.y == "up" && $_body.hasClass("js-faded-audio-scroll")) {
          $_body.removeClass("js-faded-audio-scroll");
          _audioObj.fadeIn("scroll"); 
        }
      } else {
        if(_scrollY > _globalViewportH && _scrollDirection== "down" && !$_body.hasClass("js-faded-audio-scroll")) {
          $_body.addClass("js-faded-audio-scroll");
          _audioObj.fadeOut("scroll");
        }
        else if(_scrollY < _globalViewportH && _scrollDirection== "up" && $_body.hasClass("js-faded-audio-scroll")) {
          $_body.removeClass("js-faded-audio-scroll");
          _audioObj.fadeIn("scroll"); 
        }
      }

      if(verge.inViewport($(".made-in-africa .video-holder")))
        $(".made-in-africa .video-holder")[0].play();
      else
        $(".made-in-africa .video-holder")[0].pause();
    }
  }
} //////end function aboutPage