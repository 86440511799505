function homePage(do_home) {

  /*variables*/

  var arr = [],
      canHover = false;


  /*DOM variables*/
  var $buttonWrapper = $(".page-header .button-wrapper"),
      $headerLinks = $(".header-links"),
      $hamburguerSpan = $("#hamburguer-container #lines span"),
      $hamburguerPath = $("#hamburguer-container .arrow-menu path"),
      $ciproLogo = $("#cipro-logo svg path"),
      $frame = $("#hamburguer-container .frame-menu polyline"),
      $introductionText = $(".introduction-text"); 


  var init = function() {
    if(_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      _scrollRef = function() { _raf_loop_id = _rAF_loop( home_scroll_rAF ); }
      $_window[0].addEventListener("scroll", _scrollRef, { passive: true } );
    }
    else {
      _page_scroll_listener = function(status) {
        home_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }

    /*inits*/

    if ($_body.hasClass("phone")) {
      var playPromise = $("#mobile-video")[0].play();
      if (playPromise !== undefined) {
            playPromise.then(function() {
              // alert("entrou")
            }).catch(function(error) {
              // alert("NAO entrou")
            });
      }
      $("#blurMe").find("feGaussianBlur").attr("stdDeviation", 2);
    }

    if($_body.hasClass("js-no-ajax")) {
      _audioObj = initAudios({
        defaultVolume: .5,
        fadeTime: 1000,
        audioFile: '/public/video/audio/home.mp3',
        sprite: null
      });
      if(_audioObj)
        _audioObj.init(); 
    }

    breakIntroLines($introductionText);
    if(!$_body.hasClass("mobile")) {
      for (var i = 0; i < $(".introduction-text .line").length; i++) {
        if (i == 0) {
          $(".introduction-text .line").eq(i).css("opacity", 0.25);
        }
        $(".introduction-text .line").eq(i).css("opacity", 0.25 - (i*0.1));
        arr.push(0.3 - (i*0.1));
      }
    }

    addEvents();
  }

  var kill = function() {
    $(document).off("mouseleave.homePage");
    if(_customScroll)
      _customScroll.removeListener(_page_scroll_listener);
  }

  return {
    init: init,
    kill: kill
  }
  
  /*page functions*/

  function addEvents() {

    $(document).on("mouseleave.homePage", function() {
      if (!canHover) {
        canHover = true;
        for (var i = arr.length; i >= 0; i--) {
          TweenMax.to($(".introduction-text .line").eq(i), 0.8, {opacity: arr[i], delay: 0.5-(i*0.1), ease: Quad.easeOut, onComplete: function(){
            canHover = false;
          }});
        }
      }
    });

    /*events*/
    $buttonWrapper.on("click", function(){
      if (_canClick) {
        $_hamburguer.click();
      }
    });

    $introductionText.hover(function(){
      if (!canHover) {
        TweenMax.staggerTo(".introduction-text .line", 1, {opacity: 1, ease: Quad.easeOut }, 0.05, function() { canHover = true; });
      }
    }, function(){
        canHover = true;
        for (var i = arr.length; i >= 0; i--) {
          TweenMax.to($(".introduction-text .line").eq(i), 0.8, {opacity: arr[i], delay: 0.5-(i*0.1), ease: Quad.easeOut, onComplete: function(){
            canHover = false;
          }});
        }
    });
  }
  function home_scroll_rAF(status) {
    
    if(status.offset.y > _globalViewportH && status.direction.y == "down" && !$_body.hasClass("js-faded-audio-scroll")) {
      $_body.addClass("js-faded-audio-scroll");
      _audioObj.fadeOut("main");  
    }
    else if(status.offset.y < _globalViewportH && status.direction.y == "up" && $_body.hasClass("js-faded-audio-scroll")) {
      $_body.removeClass("js-faded-audio-scroll");
      _audioObj.fadeIn("main");  
    }
    
  }

} //////end function homePage