function contactsPage(do_contacts) {

  /*variables*/
  var reachFooterObj = reachFooter();

  /*DOM variables*/
  var $form = $("#form"),
      $jobInterest = $("#radio-2"),
      $formPhone = $("#phone"),
      $phone = $(".telephone"),
      $address = $(".address"),
      $lastName = $("#last-name"),
      $buttonWrapper = $(".button-wrapper"),
      $message = $("form textarea"),
      $jobsButton = $(".jobs-careers .button-wrapper"),
      $input = $(".contact-form input, .contact-form textarea"),
      $wrapperInput = $(".contact-form .wrapper-input"),
      $mouseLoading = $(".mouse-loading"),
      $inputArea = $(".contact-form .input-area");


  var init = function() {
    if(_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      _scrollRef = function() { _raf_loop_id = _rAF_loop( contacts_scroll_rAF ); }
      $_window[0].addEventListener("scroll", _scrollRef, { passive: true } );
    }
    else {
      _page_scroll_listener = function(status) {
        contacts_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }

    /*inits*/
    if ($_body.hasClass("phone")) {
      var playPromise = $("#mobile-video")[0].play();
      if (playPromise !== undefined) {
            playPromise.then(function() {
              // alert("entrou")
            }).catch(function(error) {
              // alert("NAO entrou")
            });
      }
    }
    inputAnimations();
    
    $message.css("height", $lastName.closest($inputArea).outerHeight(true) * 3 - parseInt($lastName.closest($inputArea).css("marginBottom")) + "px");
    $form.find($buttonWrapper).css("margin-top", parseInt($lastName.closest($inputArea).css("marginBottom")) + ($formPhone.outerHeight() - $form.find($buttonWrapper).outerHeight()) - 3 + "px");

    if($_body.hasClass("js-no-ajax")) {
      _audioObj = initAudios({
        defaultVolume: .5,
        fadeTime: 1000,
        audioFile: '/public/video/audio/contacts_sprite.mp3',
        sprite:  {
          header: [0, 76852.24489795919, true],
          scroll: [78000, 76852.24489795917, true]
        }
      });
      if(_audioObj)
        _audioObj.init();
    }

    _creditsObj = credits();
    if (_creditsObj) {
      _creditsObj.init();
    }

    addEvents();
  }

  var kill = function() {
    if(_customScroll)
      _customScroll.removeListener(_page_scroll_listener);
  }
  
  return {
    init: init,
    kill: kill
  }
  

  /*page functions*/
  function addEvents() {
    if (!$_body.hasClass("mobile")) {
      function moveLoading(e){
        TweenMax.to( $mouseLoading, 0.1, {
          x: e.clientX,
          y: e.clientY + _scrollY,
          force3D: true,
          ease:Linear.easeNone
        });
      }
    }
    /*events*/
    $jobsButton.on("click", function(){
      if (!$_html.hasClass("ie") || !$_html.hasClass("edge")) {
        _customScroll.scrollTo(0, $form.offset().top + _customScroll.offset.y + (parseInt($(".form-container").css("paddingTop"))/2), 3000);
      } else {
        TweenMax.to($_window, 3, {scrollTo: {y: $form.offset().top + (parseInt($(".form-container").css("paddingTop"))/2)}, ease: Expo.easeOut});
      }
      $jobInterest.prop('checked', true);
    });

    $address.hover(function(){
      var $this = $(this);
      TweenMax.to($this, 0.6, {skewX: -10, ease: Quad.easeOut});
    }, function(){
      var $this = $(this);
      TweenMax.to($this, 0.6, {skewX: 0, ease: Quad.easeOut});
    });

    $phone.hover(function(){
      var $this = $(this);
      TweenMax.to($this, 0.6, {skewX: -10, ease: Quad.easeOut});
    }, function(){
      var $this = $(this);
      TweenMax.to($this, 0.6, {skewX: 0, ease: Quad.easeOut});
    });


    //Form events
    $form.on("submit", function() {
       var $this = $(this);

      event.stopImmediatePropagation();
      event.preventDefault();
      event.stopPropagation();

      if (validateForm($form) && !$form.hasClass('sending')) {
        $form.addClass('sending');
        TweenMax.set( $mouseLoading, {
          x: _mouseX,
          y: _mouseY + _scrollY
        });
        $form.on('mousemove.Loading', moveLoading);
        TweenMax.set($mouseLoading, {autoAlpha: 1});
        TweenMax.to($mouseLoading.find("span:first-of-type"), 0.6, {scale: 0.3, repeat: -1, yoyo: true, ease: Quad.easeOut});
        TweenMax.to($mouseLoading.find("span:last-of-type"), 0.6, {scale: 0.7, repeat: -1, yoyo: true, delay: 0.2, ease: Quad.easeOut});

         var formResponse = {
          "_wp_http_referer" : $form.find("input[name='_wp_http_referer']").val(),
          "cipro_nonce" : $form.find("input[name='cipro_nonce']").val(),
          "first-name": $form.find("#first-name").val(),
          "last-name": $form.find("#last-name").val(),
          "email": $form.find("#email").val(),
          "phone": $form.find("#phone").val(),
          "message": $form.find("#message").val(),
          "type": $form.find("input[name=radio]:checked").val()
        };

        submitJSON("saveContactForm", formResponse, function(data) {
          //Success
          console.log("success",data);
          $wrapperInput.each(function(){
            var $this = $(this);
            $this.addClass("success");
          });
          TweenMax.set($mouseLoading.find("span:first-of-type"), {backgroundColor: "#2ecc71", opacity: 1});
          TweenMax.set($mouseLoading.find("span:last-of-type"), {backgroundColor: "#2ecc71", opacity: 0.2});
          $.doTimeout(3000, function(){
            $wrapperInput.removeClass("success");
            $wrapperInput.removeClass("is-focused");
            $wrapperInput.find("input, textarea").val("");
            TweenMax.to($mouseLoading.find("span:first-of-type"), 0.6, {scale: 0, ease: Quad.easeOut});
            TweenMax.to($mouseLoading.find("span:last-of-type"), 0.6, {scale: 0, delay: 0.2, ease: Quad.easeOut, onComplete: function(){
              TweenMax.set($mouseLoading.find("span:first-of-type"), {backgroundColor: "#a48254", opacity: 1});
              TweenMax.set($mouseLoading.find("span:last-of-type"), {backgroundColor: "#a48254", opacity: 0.2});
            }});
          });
        }, function(data) {
          //Error
          console.log("error", data);
          $wrapperInput.each(function(){
            var $this = $(this);
            $this.addClass("error");
          });
          TweenMax.set($mouseLoading.find("span:first-of-type"), {backgroundColor: "#e74c3c", opacity: 1});
          TweenMax.set($mouseLoading.find("span:last-of-type"), {backgroundColor: "#e74c3c", opacity: 0.2});
          $.doTimeout(3000, function(){
            $wrapperInput.removeClass("error");
            $wrapperInput.removeClass("is-focused");
            $wrapperInput.find("input, textarea").val("");
            TweenMax.to($mouseLoading.find("span:first-of-type"), 0.6, {scale: 0, ease: Quad.easeOut});
            TweenMax.to($mouseLoading.find("span:last-of-type"), 0.6, {scale: 0, delay: 0.2, ease: Quad.easeOut, onComplete: function(){
              TweenMax.set($mouseLoading.find("span:first-of-type"), {backgroundColor: "#a48254", opacity: 1});
              TweenMax.set($mouseLoading.find("span:last-of-type"), {backgroundColor: "#a48254", opacity: 0.2});
            }});
          });
        });
      }
    });

  }

  function inputAnimations(){
    $input.on('keyup', checkVal);
    $input.on('click', function(){
      var $this = $(this);
      $this.closest($wrapperInput).addClass("is-focused");
    });

    $input.on("keydown", function(e) {
      var code = e.keyCode || e.which,
          $this = $(this);
      if (code == 9)
        if ($this.closest($inputArea).next($inputArea).is($inputArea)) {
          $this.closest($inputArea).next($inputArea).find($wrapperInput).addClass("is-focused");
        } else {
          $this.closest($form).find("textarea").closest($wrapperInput).addClass("is-focused");
        }
    });

    function checkVal(){
      var $this = $(this);
      if ($this.val() != ' ')
        if ($this.hasClass("no-value")) {
          $this.removeClass("no-value");
        }
    }

    $input.blur(function() {
      var $this = $(this);
      if(!$.trim($this.val()).length){
        $this.closest($wrapperInput).removeClass("is-focused");
        $this.addClass("no-value");
      }
    });
  }

  function contacts_scroll_rAF(status) {
    if(_customScroll)
      _scrollLimit = _customScroll.limit.y;
    
    if (!$_body.hasClass("mobile")) {
      if(_scrollY > _globalViewportH)
        $(".js-menu-video")[0].pause();
      else
        $(".js-menu-video")[0].play();
    }
    
    reachFooterObj.animate();

    if (!$_body.hasClass("mobile")) {
      if (!$_html.hasClass("ie") && !$_html.hasClass("edge")) {
        if(status.offset.y > _globalViewportH && status.direction.y == "down" && !$_body.hasClass("js-faded-audio-scroll")) {
          $_body.addClass("js-faded-audio-scroll");
          _audioObj.fadeOut("scroll"); 
        }
        else if(status.offset.y < _globalViewportH && status.direction.y == "up" && $_body.hasClass("js-faded-audio-scroll")) {
          $_body.removeClass("js-faded-audio-scroll");
          _audioObj.fadeIn("scroll");  
        }
      } else {
        if(_scrollY > _globalViewportH && _scrollDirection == "down" && !$_body.hasClass("js-faded-audio-scroll")) {
          $_body.addClass("js-faded-audio-scroll");
          _audioObj.fadeOut("scroll"); 
        }
        else if(_scrollY < _globalViewportH && _scrollDirection == "up" && $_body.hasClass("js-faded-audio-scroll")) {
          $_body.removeClass("js-faded-audio-scroll");
          _audioObj.fadeIn("scroll");  
        }
      }
    }
  }

} //////end function contactsPage