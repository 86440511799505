function four04Page(do_four04) {

  /*variables*/

  /*DOM variables*/
  var $form = $("#form");


  var init = function() {
    if(_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      _scrollRef = function() { _raf_loop_id = _rAF_loop( contacts_scroll_rAF ); }
      $_window[0].addEventListener("scroll", _scrollRef, { passive: true } );
    }
    else {
      _page_scroll_listener = function(status) {
        contacts_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }

    /*inits*/
    if ($_body.hasClass("phone")) {
      var playPromise = $("#mobile-video")[0].play();
      if (playPromise !== undefined) {
            playPromise.then(function() {
              // alert("entrou")
            }).catch(function(error) {
              // alert("NAO entrou")
            });
      }
    }

    addEvents();
  }

  var kill = function() {
    if(_customScroll)
      _customScroll.removeListener(_page_scroll_listener);
  }
  
  return {
    init: init,
    kill: kill
  }
  

  /*page functions*/
  function addEvents() {
    if (!$_body.hasClass("mobile")) {
      $(".video-canvas-wrapper-404").find("video")[0].play();
    }
  }

  function contacts_scroll_rAF(status) {
    
  }

} //////end function contactsPage