/*! -------------------------------------------------------------------------------------------
JAVASCRIPT main engine!

* @Version:    1.0 - 2016
* @author:     Burocratik
* @email:      hello@burocratik.com
* @website:    http://www.burocratik.com
* @preserve
NOTES:
:: js-no-ajax class on body (nao pode ser no html) > take it off with js as soon I work with ajax
:: js-no-ajax = did refresh
:: body.js-byrefresh= when i start by direct link (refresh) do no show content before loading
:: #loading-page.js-loading-page = i need separate byrefresh of this when I have js off
:: js-loading-page = can be used if I need a global style only when I am loading content
:: mobile = tag html is via js, tag body is via php (can't be on html tag or is deleted), also used for IE<=10
:: _global_allowNavigate = do not allow multiple cliks to load ajax (arrow, keys, click)
:: js-no-transPage = when I want a domain link with no transition ajax animation
--------------------------------------------------------------------------------------------*/
$(document).ready(function() {
    //** outdatedbrowser.com
    // Must be the first to be call or in older browsers IE6,7 will have weird js erros on my code, and the plugin will not work
    outdatedBrowser({
        bgColor: '#f25648',
        color: '#fff',
        lowerThan: 'boxShadow',
        languagePath: ''
    });
    
    //** MODERNIZR not supporter properties
    Modernizr.addTest('backgroundcliptext', function() {
        var div = document.createElement('div');
        div.style.cssText = Modernizr._prefixes.join('background-clip:text;');
        return !!div.style.cssText.replace(/\s/g, '').length;
    });
    Modernizr.addTest('object-fit', !!Modernizr.prefixed('objectFit') );

    //BURO SIGNATURE CONSOLE
    if(navigator.userAgent.toLowerCase().indexOf("chrome")>-1 || navigator.userAgent.toLowerCase().indexOf("firefox")>-1) {
      var e=["%c Made by Büro %c https://burocratik.com %c 🤘 %c\n",
      "color: #fff; background: #0020f4; padding:5px 0;",
      "color: #fff; background: #242424; padding:5px 0 5px 5px;",
      "background: #242424; padding:5px 0",
      "background: #242424; padding:5px 5px 5px 0"];
      window.console.log.apply(console, e)
    } else{
      window.console&&window.console.log("Made by Büro - https://burocratik.com")
    }
    /////////////////////////
}); //END LOAD DOCUMENT


/********************************************************************************************
 **                                                                                       **
      =LOADING PAGES, SECTIONS - =transitions between pages, =ajax
 **                                                                                       **
*********************************************************************************************/
//** MAIN LOAD
function loadPages(newContent, pageTransition) { 
    var $currentPage = $(".page-main.page-current"),
        $nextContent = $(".page-main.page-next"); // can't be global

    $("html,body").addClass("fixed-all");
    $_body.removeClass('js-no-ajax'); // I am using =ajax
    $_body.addClass("js-loading-page"); // loading by ajax (removed onStartPage())
    var $mouseLoading = $(".mouse-loading");

    if(_currentView) {
      _currentView.kill();
      _currentView = null;
    }

     TweenMax.set( $mouseLoading, {
      x: _mouseX,
      y: _mouseY,
      force3D: true,
      ease:Linear.easeNone
    });
    $_window.on('mousemove.loadingAjax', moveLoading);
    function moveLoading(e){
        TweenMax.to( $mouseLoading, 0.1, {
          x: e.clientX,
          y: e.clientY + _scrollY,
          force3D: true,
          ease:Linear.easeNone
        });
    }

    $_window.off('mousemove.menuDrag');
    $("#mouse-drag").css("display", "none");
      
    
    if(_audioObj){
      _audioObj.kill();
      _audioObj.fadeOut('scroll');
      _audioObj.fadeOut('header');
    }

    // if(_tickSoundObj)
    //   $.doTimeout(850, function() {
    //     _tickSoundObj.transition();
    //   })

    TweenMax.set($mouseLoading, {autoAlpha: 1});
    TweenMax.to($mouseLoading.find("span:first-of-type"), 0.6, {scale: 0.3, repeat: -1, yoyo: true, ease: Quad.easeOut});
    TweenMax.to($mouseLoading.find("span:last-of-type"), 0.6, {scale: 0.7, repeat: -1, yoyo: true, delay: 0.2, ease: Quad.easeOut});

    $nextContent.load(newContent + " .page-toload", function(response, status, xhr) {
        var $this = $(this);
        if( !$this.html()  ) { //=404
            window.location = newContent;
            return false;
        }
        
        $.doTimeout(500, function() {
          if($nextContent.find(".page-toload").hasClass("about-page")) {
            _audioObj = initAudios({
              defaultVolume: .5,
              fadeTime: 1000,
              audioFile: '/public/video/audio/about_sprite.mp3',
              sprite:  {
                header: [0, 96052.24489795919, true],
                scroll: [98000, 76852.24489795917, true]
              }
            });
          }
          else if($nextContent.find(".page-toload").hasClass("concept-page")) {
            _audioObj = initAudios({
              defaultVolume: .5,
              fadeTime: 1000,
              audioFile: '/public/video/audio/concept.mp3',
              sprite: null
            });
          }
          else if($nextContent.find(".page-toload").hasClass("contacts-page")) {
            _audioObj = initAudios({
              defaultVolume: .5,
              fadeTime: 1000,
              audioFile: '/public/video/audio/contacts_sprite.mp3',
              sprite:  {
                header: [0, 76852.24489795919, true],
                scroll: [78000, 76852.24489795917, true]
              }
            });
          }
          else if($nextContent.find(".page-toload").hasClass("home-page")) {
            _audioObj = initAudios({
              defaultVolume: .5,
              fadeTime: 1000,
              audioFile: '/public/video/audio/home.mp3',
              sprite: null
            });
          }
          else if($nextContent.find(".page-toload").hasClass("work-page")) {
            _audioObj = initAudios({
              defaultVolume: .5,
              fadeTime: 1000,
              audioFile: '/public/video/audio/work_sprite.mp3',
              sprite:  {
                header: [0, 76852.24489795919, true],
                scroll: [78000, 57652.24489795918, true]
              }
            });
          }
          if(_audioObj)
            _audioObj.init();
        })
        
       
      
      
          

        $(".audio-helper-container").removeClass("whiteColor");
        
        TweenMax.to($mouseLoading.find("span:first-of-type"), 0.6, {scale: 0, ease: Quad.easeOut});
        TweenMax.to($mouseLoading.find("span:last-of-type"), 0.6, {scale: 0, delay: 0.2, ease: Quad.easeOut, onComplete: function() { $_window.off('mousemove.loadingAjax'); }});

        _canvasVideoObj.zoomIn("ajax");

    }) // end nextContent load
    return;
} //////end function main load content


function clearPagesAfterloading(delay) {
  var $currentPage = $(".page-main.page-current"),
      $nextContent = $(".page-main.page-next"); // can't be global

  $.doTimeout(delay, function() {
    $currentPage.remove();
    $nextContent.removeClass('page-next');
    $nextContent.addClass('page-current').removeAttr("aria-hidden");
    var $newCurrentPage = $(".page-main.page-current");
    $newCurrentPage.after('<div class="page-main page-next" aria-hidden="true"></div>');

    onStartPageWhenRefresh(false);
    $newCurrentPage.attr("style", "");
    window.scrollTo(0, 0);
    $("html,body").scrollTop(0);
  }) //end do timeout
} //end function

/********************************************************************************************
 **                                                                                       **
     =START EACH PAGE - refresh or ajax
 **                                                                                       **
*********************************************************************************************/
function onStartPageWhenRefresh(byRefresh) {
    if (byRefresh) {
        // :BUG CHROME: even wit this is not scrolling top is some section, needed hack after preloading with animate
        window.scrollTo(0, 0);
        $("html,body").scrollTop(0);
        //
        $("html,body").addClass("fixed-all");
        $_loadingPage.addClass('js-loading-page');
        $_body.removeClass("js-byrefresh");

        manageBodyClasses();
        headerAnimations();

        $.doTimeout(1000, function(){
            if (!$_body.hasClass("home")) {
                $pageName = $(".type-page-name ").html($(".page-main.page-current .page-toload").attr("data-titlePage"));
                breakTitleLetters($pageName);
                anime.timeline()
                  .add({
                    targets: '.type-menu .char',
                    translateY: [0, -100],
                    easing: "easeInQuad",
                    duration: 1400,
                    offset: "-=500",
                    delay: function(el, i) {
                      return 500 + 50 * i;
                    }
                  }).add({
                    targets: '.type-page-name .char',
                    translateY: [100,0],
                    easing: "easeOutQuad",
                    duration: 1100,
                    offset: "-=2500",
                    delay: function(el, i) {
                      return 500 + 50 * i;
                    }
                  });
            }
        });
        
        if($_body.hasClass("phone")) {
          $_toPreload.imagesLoaded(function($images, $proper, $broken) {
              var fPreload = $(this).imagesLoaded();
                fPreload.always(function() {

                    $("html,body").animate({
                        scrollTop: 0
                    }, 100); // :BUG CHROME: 100ms is arbitrary
                    // $_toPreload.remove(); because i need for section name

                    // if browser does not suport object-fit: cover
                    if ( $_html.hasClass("no-object-fit") )  {
                        var $element = $(".page-current .element-cover");
                        resizeLikeCover($element);
                    }

                    ///
                    TweenMax.to($_loadingPage, .6, {
                      opacity: 0,
                      ease:Power2.easeInOut,
                      onComplete: function(){
                        $("html,body").removeClass("fixed-all");
                        $_loadingPage.removeClass('js-loading-page').hide();
                        onStartPage();
                      }
                    });
                }); //end always
            }); //end preload images
        }
        else {
          $(document).on("videoLoaded", function() {
            $_toPreload.imagesLoaded(function($images, $proper, $broken) {
              var fPreload = $(this).imagesLoaded();
                fPreload.always(function() {

                    $("html,body").animate({
                        scrollTop: 0
                    }, 100); // :BUG CHROME: 100ms is arbitrary
                    // $_toPreload.remove(); because i need for section name

                    // if browser does not suport object-fit: cover
                    if ( $_html.hasClass("no-object-fit") )  {
                        var $element = $(".page-current .element-cover");
                        resizeLikeCover($element);
                    }

                    ///
                    TweenMax.to($_loadingPage, .6, {
                      opacity: 0,
                      ease:Power2.easeInOut,
                      onComplete: function(){
                        $("html,body").removeClass("fixed-all");
                        $_loadingPage.removeClass('js-loading-page').hide();
                        onStartPage();
                      }
                    });
                }); //end always
            }); //end preload images
          })
        }
        
        
    //for history: When your page loads, it might have a non-null state object and the page will receive an onload event, but no popstate event.
    //forPopstate=true;

    } else {
        onStartPage();
    }
} //////end function

/*-------------------------------------------------------------------------------------------
    =STARTPAGE - EACH PAGE - call of functions and events
--------------------------------------------------------------------------------------------*/
function onStartPage() {
    var do_home,
        do_about,
        do_concept,
        do_work,
        do_contacts,
        do_four04;

    // ** =ALLOW user load other pages
    _global_allowNavigate = true;

    window.cancelAnimationFrame(_raf_main_id);

    /*Refresh global values*/
    $_pageHeader = $(".page-current .page-header");
    $_pageFooter = $(".page-current .page-footer");
    $_pageContent = $(".page-current .page-content");
    $_pageToLoad = $(".page-current .page-toload");

    if(_customScroll != null){
        _customScroll.update();
    }
    if($_scrollContentWrapper.attr("data-scrollbar") != undefined && !$_scrollContentWrapper.hasClass("js-scroll")) {
        _customScroll = Scrollbar.init($_scrollContentWrapper[0], { speed: 0.8, syncCallbacks: true });
        $_scrollContentWrapper.addClass("js-scroll");
    }
    if(_customScroll != null) {
        $(".loading-container").css({
           'top' : 0
        });
        _customScroll.setPosition(0,0);
        _customScroll.removeListener(_page_scroll_listener);
    }

    // ** =REMOVE classes of loading (if needed)
    $("html,body").removeClass("fixed-all");
    $_body.removeClass("js-loading-page");

    // **=POLYFILL ASYNC
    callAsyncFunctions();

    initGlobalAnimations();

    // // ** =HOME
    // ($(".page-current .home-page").length) ? do_home = true : do_home = false;
    // homePage(do_home);

    // // ** =ABOUT
    // ($(".page-current .about-page").length) ? do_about = true : do_about = false;
    // aboutPage(do_about);

    // // ** =CONCEPT
    // ($(".page-current .concept-page").length) ? do_concept = true : do_concept = false;
    // conceptPage(do_concept);

    // // ** =WORK
    // ($(".page-current .work-page").length) ? do_work = true : do_work = false;
    // workPage(do_work);

    // // ** =CONTACTS
    // ($(".page-current .contacts-page").length) ? do_contacts = true : do_contacts = false;
    // contactsPage(do_contacts);


    if($_pageToLoad.hasClass("home-page")) {
      _currentView = homePage();
      _currentView.init();
    }
    if($_pageToLoad.hasClass("about-page")) {
      _currentView = aboutPage();
      _currentView.init();
    }
    if($_pageToLoad.hasClass("concept-page")) {
      _currentView = conceptPage();
      _currentView.init();
    }
    if($_pageToLoad.hasClass("work-page")) {
      _currentView = workPage();
      _currentView.init();
    }
    if($_pageToLoad.hasClass("contacts-page")) {
      _currentView = contactsPage();
      _currentView.init();
    }
    if($_pageToLoad.hasClass("four04-page")) {
      _currentView = four04Page();
      _currentView.init();
    }
    var index = $(".page-main.page-current .page-toload").attr("data-titlePage");

    // ** =scrolling events
    //whenScrolling(true);

    // ** =URL com ancoras onload
    var hasHash = window.location.hash;
    if ( hasHash != "") {
      var $toGoHere = $(""+hasHash+"")
        $.doTimeout(500, function() {
          goTo( $toGoHere, 1500, [0.7,0,0.3,1], 0);
        });
      //
    }

    // ** =RESIZE ELEMENTS LIKE BACKGROUND COVER (browser does not support object-fit: cover)
    if ( $_html.hasClass("no-object-fit") )  {
        var $element = $(".page-current .element-cover");
        resizeLikeCover($element);
    }

} //////end function StartPage


/*******************************************************************************************
 **                                                                                       **
    =MAIN =NAVIGATION and FORM NEWSLETTER
 **                                                                                       **
*********************************************************************************************/
function mainNavigation() {
    var number = $(".page-current .page-header").attr("data-index"),
        $scrollIndicatorMenu = $("#menu-container #page-indicator-container"),
        $buttonDiscover = $(".discover-line-btn"),
        $typeMenuSpan = $(".type-menu-container span");

    TweenMax.set($_scrollHelper, {top: _scrollY + _globalViewportH - $_scrollHelper.outerHeight() - 45})
    TweenMax.set($_audioHelper, {top: _scrollY + _globalViewportH - $_audioHelper.outerHeight() - 45})

    //GLOBAL MENU
    if (!$_body.hasClass("mobile")) {
        $("#page-indicator-container").css("height", $("#page-indicator-container ul").find("li").height()*7 + "px");
        $("#page-indicator-container ul").css("width", $("#menu-container #menu-carousel ul").find("li").eq(0).width() + "px");
        $("#full-line-container #line").css("width", $("#page-indicator-container ul li").width() + "px");
        $scrollIndicatorMenu.css("left", (_globalViewportW-$scrollIndicatorMenu.width()) / 2 + "px");
        $scrollIndicatorMenu.css("top",_globalViewportH - $scrollIndicatorMenu.outerHeight() - 50);
    } else {
        
    
        var $teste = $("#page-indicator-container");
        Scrollbar.init($teste[0], { speed: 0.8, syncCallbacks: true });

    }
    $buttonDiscover.each(function(){
        var $this = $(this);
        breakTitleLetters($this);
    });
    $typeMenuSpan.each(function(){
        var $this = $(this);
        breakTitleLetters($this);
    });

    //LANG
    $("#lang-container li a").each(function(){
        var $this = $(this);
        breakTitleLetters($this);
    });

    globalStickyHeader();
    if (!$_body.hasClass("phone")) {
      menuDrag();
    }
    changeMenuIndex(number);
    _tickSoundObj.init();
    if (!$_body.hasClass("home")) {
      _canClick = true;
    }
} // end function

/*******************************************************************************************
 **                                                                                       **
    =globa1Animations
 **                                                                                       **
*********************************************************************************************/
function initGlobalAnimations() {

  _scrollAnimations = initScrollAnimations();
  _scrollAnimations.init();

  initViewportAnimations();

  
  if(_customScroll == null)
    _scrollLimit = $_html.height();
  else
    _scrollLimit = _customScroll.limit.y;

  _raf_main_id = _rAF_loop( animationLoop );
  animationLoop();

  function animationLoop() {
   _raf_main_id =  _rAF_loop( animationLoop );

    if(_customScroll == null) {
      _scrollY = window.pageYOffset;
    }
    else {
      _scrollY = _customScroll.offset.y;
    }

    if(_lastScroll != _scrollY && _scrollY != 0 && !_blockAll) {

      if(_lastScroll < _scrollY )
        _scrollDirection = 'down';
      else
        _scrollDirection = 'up';

       _lastScroll = _scrollY;

    }
  }
}

/*******************************************************************************************
 ****                                                                                   ****
    =DOCUMENT =READY =START Document ready
 ****                                                                                   ****
*********************************************************************************************/
$(document).ready(function() {
    //** =Global objects
    $_window = $(window),
    $_body = $("body"),
    $_html = $("html"),
    $_mainPage = $(".page-main"),
    $_btn_nav_main = $(".btn-nav-main");

    $_currentPage = $(".page-main.page-current"),
    $_toPreload = $(".preload"),
    $_loadingPage = $("#loading-page"),
    $_headerMain = $("#header-main"),
    $_hamburguer = $("#hamburguer-container"),
    $_scrollContentWrapper = $(".scroll-content-wrapper");
    $_audioHelper = $(".audio-helper-container");
    $_scrollHelper = $(".scroll-helper");
    $_mainVideo = $(".video-holder").clone(true);

    $_pageHeader = $(".page-current .page-header");
    $_pageFooter = $(".page-current .page-footer");
    $_pageContent = $(".page-current .page-content");
    $_pageToLoad = $(".page-current .page-toload");


    //** =Global variables
    calculateGlobalValues();
    _global_allowNavigate = false; //When loading do not allow clicks by user ( onStartPage revers to true)
    _server_hostname = window.location.hostname;
    _page_scroll_listener = null;
    // _pageAudio = initPageSound();
    _audioHelper = audioHelper();
    _menuObj = menuAnimations();
    _tickSoundObj = tickSound();

    _raf_main_id = null;

    _customScroll = null,
    _lastScroll = -1;
    _scrollY = 0;
    _scrollAnimations = null;
    _scrollLimit = 1;
    _scrollDirection = 'down';
    _blockAll = false;
    _pageTransition = null;
    _canOpenMenu = false;
    _canClick = false;
    _audioObj = false;
    _creditsObj = false;
    _canvasVideoObj = null;
    _mainVideoTime = 0;

    _currentView = null;

    _mouseX = 0;
    _mouseY = 0;


    if(!cookie.get('audioMute'))
      cookie.set('audioMute', false);

    if($_html.hasClass("ie") || $_html.hasClass("edge") || $_body.hasClass("mobile")) {
      $(".scroll-content-wrapper").removeAttr("data-scrollbar");
    }

    if($_scrollContentWrapper.attr("data-scrollbar") != undefined && !$_scrollContentWrapper.hasClass("js-scroll")) {
      $_body.css({"overflow": "hidden"});
      $_html.css({"overflow": "hidden"});
      _customScroll = Scrollbar.init($_scrollContentWrapper[0], { speed: .8, syncCallbacks: true });
      $_scrollContentWrapper.addClass("js-scroll");
    }

    // Request Animation Frame
    _rAF_loop = window.requestAnimationFrame ||
               window.webkitRequestAnimationFrame ||
               window.mozRequestAnimationFrame ||
               window.msRequestAnimationFrame ||
               window.oRequestAnimationFrame ||
               // IE Fallback, you can even fallback to onscroll
               function(callback){ window.setTimeout(callback, 1000/60) };

    _cancelAnimationFrame = window.cancelAnimationFrame || 
                            window.mozCancelAnimationFrame || 
                            window.mozCancelAnimationFrame
                            || function(requestID){clearTimeout(requestID)} //fall back
    _raf_loop_id = null;

     if (!$_body.hasClass("phone") && !$_pageToLoad.hasClass("four04-page")) {
      _canvasVideoObj = canvasHeaderVideo();
      _canvasVideoObj.init();
    }
    //** =START PAGES
    onStartPageWhenRefresh(true);
    callAsyncFunctions();

    //initGlobalAnimations();
    mainNavigation();

    // VW/VH Unis fix for IOS
    window.viewportUnitsBuggyfill.init();

    //Start Plugins
    FastClick.attach(document.body); //no 300ms tap delay!

  /** -----------------------------------------------------------------------------------------
  =LOAD SECTIONS - triger events =CLICK to LOAD PAGE
  body class="js-no-ajax ismobile" > inserida via php == no nosso caso a mobile
  --------------------------------------------------------------------------------------------*/
    var domainSite = window.location.host;
    var mainTitle = " | CIPRO",
        homeTitle = "CIPRO";
    _forPopstate = true;
    $_linkInternal = $('a[href*="'+domainSite+'"]');

    $(document).on('click', 'a[data-remote="true"]', function() {
        var $this = $(this);

        // **ALLOW user load other pages only after page is loaded ( onStartPage )
        if (!_global_allowNavigate) return false;
        _global_allowNavigate = false;

        // exit and have normal click
        if ( $_html.hasClass('mobile') || $_html.hasClass("ie") )  return true;
        if ($this.hasClass("modal") || $this.hasClass("js-no-transPage") ) return;

        //
        var thisHref = $this.attr('href'),
            thisTitle = $this.attr("data-title"),
            newContent = thisHref,
            forHistory = newContent,
            forTitle = thisTitle + mainTitle;

        //home page
        if (!thisTitle) {
            forTitle = homeTitle;
        }

        //for history
        if (_forPopstate) {
            history.pushState({}, forTitle, forHistory);
        }
        _forPopstate = true;

        // for title
        $('head title').html(forTitle);

        //ga('send', 'pageview', window.location.pathname); //analytics

        loadPages(newContent);

        return false;
    }); //end click

    /// HISTORY
    //  note: Chrome and Safari will fire a popstate event when the page loads but Firefox doesnt. When your page loads, it might have a non-null state object and the page will receive an onload event, but no popstate event. (window.history.state; on refresh page)
    if (window.addEventListener) {
        window.addEventListener('popstate', function(e) {
            if ($_html.hasClass('mobile')) return false;
            if (!e.state) {
                _forPopstate = true; return false;
            } // firefox vs webkit and safari trigers on
            _forPopstate = false;
            window.location = window.location; // reload page for this new adress!
            return false;
        });
    } //endif: does not excute for <= IE8


    /* -------------------------------------------------------------------------------------------
    =EVENTS DEFAULT BURO
    -------------------------------------------------------------------------------------------- */
    //OPEN NEW WINDOW
    $(document).on("click", "a[rel=external]", function(event) {
        event.stopImmediatePropagation();
        event.preventDefault();
        event.stopPropagation();
        var linkExterno = window.open($(this).attr("href"));
        return linkExterno.closed;
    })
    //PRINT
    $("a[rel=print]").click(function() {
        var imprimir = window.print();
        return false;
    })
    //E-MAIL: has classclass="email", e [-at-]
    $("a.email").each(function() {
        var mailReal = $(this).text().replace("[-at-]", "@");
        $(this).text(mailReal);
        $(this).attr("href", "mailto:" + mailReal);
    })
    //CLEAR FORMS
    $('input[type=text], input[type=email], input.text, input.email, textarea').each(function() {
        if (!$(this).hasClass("txtClear")) return;
        var defeito = this.defaultValue;
        $(this).focus(function() {
            if ($(this).val() == defeito) {
                $(this).val("")
            }
        });
        $(this).blur(function() {
            if ($(this).val() == "") {
                $(this).val(defeito)
            }
        });
    })
    //OPEN POPUP
    $(document).on("click", '.newWindow', function() {
        event.stopImmediatePropagation();
        event.preventDefault();
        event.stopPropagation();
        var newwindow = window.open($(this).attr('href'), '', 'height=480,width=560');
        if (window.focus) {
            newwindow.focus();
        }
        return false;
    })

    /*-------------------------------------------------------------------------------------------
    =KEYS
    --------------------------------------------------------------------------------------------*/
    $(document).on("keydown.lightbox", function(event) {
        switch (event.which) {
            case 40: //down
                return false;
                break;
            case 38: //up
                return false;
                break;
            case 39: //next
                break;
            case 37: //prev
                break;
            case 27: //close (esc)
                if (!$_body.hasClass("js-lightbox-open") && _canClick) {
                  $(document).trigger('toggleMenu');
                }
                break;
            default:
                break;
        } //end switch
    }); //end keypress

    /*-------------------------------------------------------------------------------------------
    =RESIZE on
    --------------------------------------------------------------------------------------------*/
    $_window.on('resize', $.debounce(500, function() {
        //** =recalculate global variables
        calculateGlobalValues();
        if (!$_html.hasClass("ie") || !$_html.hasClass("edge")) {
          $_audioHelper.css("top", _scrollY + _globalViewportH - $_audioHelper.outerHeight() - 45);
          $_scrollHelper.css("top", _scrollY + _globalViewportH - $_scrollHelper.outerHeight() - 45);
        }
        if (!$_body.hasClass("home")) {
          $(".page-current .page-header .frame").css({
            "top": (_globalViewportH - $(".page-current .page-header .frame").outerHeight()) / 2,
            "transform": "translateY(0)"
          });
          $(".page-current .page-footer .next-page-container .next-page-image .frame-footer").css({
            "top": ($(".page-current .page-footer .next-page-container .next-page-image").outerHeight() - $(".page-current .page-footer .next-page-container .next-page-image .frame-footer").outerHeight()) / 2
          });
          TweenMax.set(".page-current .page-footer .next-page-container .next-page-image .frame-footer", {y:0});
        }

        if ( $_html.hasClass("no-object-fit") )  {
            var $element = $(".page-current .element-cover");
            resizeLikeCover($element);
        }

    })) //end resize window


    /*-------------------------------------------------------------------------------------------
    =MOUSEMOVE on
    --------------------------------------------------------------------------------------------*/
    $_window.on('mousemove', $.debounce(100, function(e) {
        _mouseX = e.clientX;
        _mouseY = e.clientY;
    }));

    /////////////////////////
}); //END LOAD DOCUMENT

/********************************************************************************************
 **                                                                                       **
     =FORMS AJAX SUBMITS
 **                                                                                       **
*********************************************************************************************/

function submitJSON(action, value, onSuccess, onError) {
  $.ajax({
    data: ({action : action, value:value}),
    type: 'post',
    dataType: 'json',
    url: 'http://' + _server_hostname + '/wp-admin/admin-ajax.php',
    success: function(data) {
      onSuccess(data)
    },
    error: function(data) {
      onError(data)
    }
  });
}

function submitForm($form) {
  if (validateForm($form) && !$form.hasClass('sending')) {
    $form.addClass('sending');

    $.ajax({
      data: $form.serialize(),
      type: 'post',
      dataType: 'json',
      url: 'http://' + _server_hostname + '/wp-admin/admin-ajax.php',
      success: function(data) {
        $form.removeClass('sending');
      },
      error: function(data) {
        $form.removeClass('sending');
      }
    });
  }
}

/*******************************************************************************************
 **                                                                                       **
    =GENERAL FUNCTIONS AND PLUGINGS CONTROL
 **                                                                                       **
*********************************************************************************************/
/** =Global page values */
function calculateGlobalValues() {
    _globalViewportW = verge.viewportW();
    _globalViewportH = verge.viewportH();
    _globalHalfViewportH = (_globalViewportH / 2).toFixed(0);
}

/** =ASYNC PLUGINS (polyfill, etc) */
function callAsyncFunctions() {
  if( window.respimage ) respimage();
}

/** =Random integer between min (included) and max (excluded) */
function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}

/** =Manage for added body and page classes */
function manageBodyClasses() {
  if($_body.hasClass("js-no-ajax")) {
    $_body.addClass($(".page-main.page-current .page-toload").attr("data-bodyClass"));
  } else {
    $_body.removeClass($(".page-main.page-current .page-toload").attr("data-bodyClass"));
    $_body.addClass($(".page-main.page-next .page-toload").attr("data-bodyClass"));
  }
}

